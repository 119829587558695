import { FC, useEffect, useState } from "react";
import { animated, useTransition } from "react-spring";
import styles from "./DotJapanBg.module.scss";

const Pin: FC<{ className: string; src1: string; src2: string }> = ({
  className,
  src1,
  src2,
}) => {
  const [toggle, setToggle] = useState(false);

  const awajiTransitions = useTransition(toggle, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    reverse: toggle,
    delay: 1000,
    onRest: () => setToggle(!toggle),
  });

  return (
    <div className={`${styles.pin} ${className}`}>
      {awajiTransitions(({ opacity }, item) =>
        item ? (
          <animated.img
            src={src1}
            alt=""
            style={{
              position: "absolute",
              opacity: opacity.to({ range: [0.0, 1.0], output: [0, 1] }),
            }}
          />
        ) : (
          <animated.img
            src={src2}
            alt=""
            style={{
              position: "absolute",
              opacity: opacity.to({ range: [1.0, 0.0], output: [1, 0] }),
            }}
          />
        )
      )}
    </div>
  );
};

export const DotJapanBg = () => {
  const [baseSize, setBaseSize] = useState(0);

  useEffect(() => {
    const resize = () => {
      const actualWidth = Math.min(window.innerWidth * 0.5, 756);
      const baseSize = Math.min(actualWidth, window.innerHeight);

      setBaseSize(baseSize);
    };

    window.addEventListener("resize", resize);
    resize();

    return () => {
      window.removeEventListener("resize", resize);
    };
  }, []);

  const mapStyle = {
    width: baseSize * 0.95,
    height: baseSize * 0.95,
  };

  return (
    <div
      className={`fixed z-10 top-0 bottom-0 left-1/2 flex items-center mx-auto max-w-3xl`}
    >
      <div className={`relative`} style={mapStyle}>
        <img
          className="block w-full h-full"
          src="./images/company/dot_japan.png"
          alt=""
        />
        <Pin
          className={styles.awaji}
          src1="./images/company/awaji_pin_red.png"
          src2="./images/company/awaji_pin_black.png"
        />
        <Pin
          className={styles.tokyo}
          src1="./images/company/tokyo_pin_black.png"
          src2="./images/company/tokyo_pin_red.png"
        />
      </div>
    </div>
  );
};
