import { useLocation } from "react-router-dom";
import { useTransition } from "react-spring";
import {
  INTRO_DURATION_TOTAL,
  INTRO_SHORT_DURATION_TOTAL,
  TRANSITION_DURATION,
} from "../../lib/configs";
import { SITE_PATH } from "../../lib/const";
import { useAppSelector } from "../../store/storeHooks";

const transitionDurationMs = TRANSITION_DURATION * 1000;
const introDurationTotalMs = INTRO_DURATION_TOTAL * 1000;
const introShortDurationTotalMs = INTRO_SHORT_DURATION_TOTAL * 1000;

export const useTransitionRouter = () => {
  const { isFinishedIntro } = useAppSelector((state) => state.loading);
  const location = useLocation();

  let delay = transitionDurationMs;
  if (!isFinishedIntro) {
    if (location.pathname === SITE_PATH.HOME) {
      delay = introDurationTotalMs;
    } else {
      delay = introShortDurationTotalMs;
    }
  }

  const transitions = useTransition(location, {
    keys: (location) => location.pathname,
    initial: { opacity: 0.00001 },
    from: { opacity: 0 },
    enter: (item) => async (next, cancel) => {
      await next({ opacity: 0 });
      await next({ opacity: 1, delay: delay });
    },
    leave: { opacity: 0 },
  });

  return transitions;
};
