import { useState } from "react";
import { MenuButton } from "./MenuButton";
import { MenuList } from "./MenuList";

export const Menu = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isHover, setIsHover] = useState(false);

  return (
    <div className="fixed z-40 inset-0 font-sans pointer-events-none">
      {isOpen && (
        <div
          className="absolute z-10 inset-0 pointer-events-auto"
          onClick={() => setIsOpen(false)}
        />
      )}

      <MenuButton
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        setIsHover={setIsHover}
      />
      <MenuList isOpen={isOpen} isHover={isHover} setIsOpen={setIsOpen} />
    </div>
  );
};
