import { Link, useLocation } from "react-router-dom";
import { animated, useSpring } from "react-spring";
import { SITE_PATH } from "../../lib/const";
import { useAppSelector } from "../../store/storeHooks";

export const SiteLogo = () => {
  const { isFinishedIntro } = useAppSelector((state) => state.loading);
  const location = useLocation();

  const isWhite =
    location.pathname === SITE_PATH.ABOUT ||
    location.pathname === SITE_PATH.COMPANY;

  const styles = useSpring({
    opacity: isFinishedIntro ? 1 : 0,
    filter: isWhite ? "invert(1)" : "invert(0)",
  });

  if (!isFinishedIntro) return null;

  return (
    <animated.div
      className="fixed z-40 top-2 left-2 w-14 h-14 sm:w-18 sm:h-18"
      style={styles}
    >
      <Link
        className="block sm:hover:opacity-70 transition-opacity duration-100"
        to={SITE_PATH.HOME}
      >
        <img
          className="block w-full h-full object-contain"
          src="./images/site-logo.png"
          alt="株式会社庄田竜事務所ロゴ"
        />
      </Link>
    </animated.div>
  );
};
