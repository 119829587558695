import * as THREE from "three";
import * as dat from "dat.gui";
import { IS_DEBUG, IS_NOT_DEBUG } from "../lib/configs";
import Stats from "three/examples/jsm/libs/stats.module";

const IS_NEABLED_AXES_HELPER = IS_DEBUG && false;
const AXES_SIZE = 50;

const IS_ENABLED_GRID_HELPER = IS_DEBUG && true;
const GRID_SIZE = 20;
const GRID_DIVISIONS = 20;

export class TDebug {
  private static _isHelpers: boolean = false;
  private static _stats?: Stats = undefined;
  private static _gui?: dat.GUI = undefined;

  static initHelpers(scene: THREE.Scene) {
    if (IS_NOT_DEBUG) return;

    if (!TDebug._isHelpers) {
      TDebug._isHelpers = true;

      if (IS_NEABLED_AXES_HELPER) {
        const axesHelper = new THREE.AxesHelper(AXES_SIZE);
        axesHelper.position.setY(0.01);
        scene.add(axesHelper);
      }

      if (IS_ENABLED_GRID_HELPER) {
        const gridHelper = new THREE.GridHelper(GRID_SIZE, GRID_DIVISIONS);
        scene.add(gridHelper);
      }
    }
  }

  static initStats() {
    if (IS_NOT_DEBUG) return;

    if (!TDebug._stats) {
      if (IS_DEBUG) console.log("TDebug: Stats初期化");

      const stats = Stats();
      stats.showPanel(0);

      document.body.appendChild(stats.dom);
      TDebug._stats = stats;
    }
  }

  static beginStats() {
    if (IS_NOT_DEBUG) return;

    TDebug._stats?.begin();
  }

  static endStats() {
    if (IS_NOT_DEBUG) return;

    TDebug._stats?.end();
  }

  static get gui(): dat.GUI {
    if (IS_NOT_DEBUG) {
      console.error("デバッグモードでないのに関わらず、datGUI取得");
    }

    if (!TDebug._gui) {
      const gui = new dat.GUI();
      gui.closed = true;

      if (gui.domElement.parentElement) {
        gui.domElement.parentElement.style.zIndex = "10000";
      }

      TDebug._gui = gui;
    }

    return TDebug._gui;
  }
}
