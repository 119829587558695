import gsap from "gsap";
import * as THREE from "three";
import { CARD_SIZE, IS_DEBUG } from "../lib/configs";
import { TLoader } from "./TLoader";
import { TText } from "./TText";

const IMAGE_PATH = `./images/cards/`;
const IMAGE_TYPE = ".jpg";

const CARD_DEPTH = 0.02;

type CardInfoType = {
  uid: number;
  philosophy: string;
};

export class TCard {
  private static s_currentPhiY = 0;

  private _card: THREE.Group;
  private _cardMesh: THREE.Mesh;
  private _cardMaterial: THREE.MeshBasicMaterial;
  private _info: CardInfoType;
  private _tText: TText;
  private _isPhilosophyAppeared = false;
  private _tweenPos?: gsap.core.Timeline;
  private _tweenRotate?: gsap.core.Timeline;
  private _tweenOpacity?: gsap.core.Timeline;
  private _tweenPhilosophy?: gsap.core.Tween;

  constructor(cardData: CardInfoType) {
    this._info = cardData;

    const loader = TLoader.loader;
    const path = `${IMAGE_PATH}${cardData.uid}${IMAGE_TYPE}`;
    const texture = loader.load(path);

    const geometry = new THREE.BoxGeometry(CARD_SIZE, CARD_SIZE, CARD_DEPTH);
    this._cardMaterial = new THREE.MeshBasicMaterial({
      color: 0xffffff,
      map: texture,
      transparent: true,
      opacity: 0,
    });

    this._cardMesh = new THREE.Mesh(geometry, this._cardMaterial);
    this._cardMesh.layers.enable(1);

    this._card = new THREE.Group();
    this._card.add(this._cardMesh);

    // Generate Text
    this._tText = new TText(cardData.philosophy);
    this._card.add(this._tText.text);
  }

  private createPosTimeline() {
    this._tweenPos = gsap.timeline({
      onComplete: () => (this._tweenPos = undefined),
    });
  }

  private cancelPositionTo() {
    if (this._tweenPos) {
      this._tweenPos.kill();
    }
  }

  private addPositionTo(
    duration: number,
    delay: number,
    targetPos: THREE.Vector3
  ) {
    if (this._tweenPos) {
      this._tweenPos.to(this._card.position, {
        duration: duration,
        delay: delay,
        x: targetPos.x,
        y: targetPos.y,
        z: targetPos.z,
      });
    }
  }

  private createRotateTimeline() {
    this._tweenRotate = gsap.timeline({
      onComplete: () => (this._tweenRotate = undefined),
    });
  }

  private cancelRotateTo() {
    if (this._tweenRotate) {
      this._tweenRotate.kill();
    }
  }

  private addRotateTo(duration: number, delay: number, targetRot: THREE.Euler) {
    if (this._tweenRotate) {
      this._tweenRotate.to(this._card.rotation, {
        duration: duration,
        delay: delay,
        x: targetRot.x,
        y: targetRot.y,
        z: targetRot.z,
      });
    }
  }

  private cancelTweenPhilosophy() {
    if (this._tweenPhilosophy) {
      this._tweenPhilosophy.kill();
      this._tweenPhilosophy = undefined;
    }
  }

  private requestAppearPhilosophy() {
    if (this._isPhilosophyAppeared) return;

    TCard.s_currentPhiY++;
    if (TCard.s_currentPhiY > 3) TCard.s_currentPhiY = 1;

    const targetY = CARD_SIZE * TCard.s_currentPhiY * 1.05;
    this.appearPhilosophy(targetY);
  }

  private appearPhilosophy(y: number) {
    this.cancelTweenPhilosophy();
    this._isPhilosophyAppeared = true;

    this._tweenPhilosophy = gsap.to(this._cardMesh.position, {
      duration: 1,
      y: y,
      ease: "power2.out",
      onComplete: () => {
        this._tText.setPosY(y);
        this._tText.appear(3);

        this.hidePhilosophy(4);
      },
    });
  }

  private hidePhilosophy(delay: number = 0) {
    this.cancelTweenPhilosophy();

    this._tweenPhilosophy = gsap.to(this._cardMesh.position, {
      duration: 1,
      delay: delay,
      y: 0,
      ease: "power2.out",
      onStart: () => {},
      onComplete: () => {
        this._tweenPhilosophy = undefined;
        this._isPhilosophyAppeared = false;
      },
    });
  }

  onClick() {
    if (IS_DEBUG) console.log("TCard: onClick", this);

    this.requestAppearPhilosophy();
  }

  cancelMoveTo() {
    this.cancelPositionTo();
    this.cancelRotateTo();

    this.hidePhilosophy();
    this._tText.hide();
  }

  createMoveTimelines() {
    this.createPosTimeline();
    this.createRotateTimeline();
  }

  addMoveTo(
    duration: number,
    delay: number,
    targetPos: THREE.Vector3,
    targetRot: THREE.Euler
  ) {
    this.addPositionTo(duration, delay, targetPos);
    this.addRotateTo(duration, delay, targetRot);
  }

  setPosition(targetPos: THREE.Vector3) {
    this._card.position.set(targetPos.x, targetPos.y, targetPos.z);
  }

  setRotation(targetRot: THREE.Euler) {
    this._card.rotation.set(targetRot.x, targetRot.y, targetRot.z);
  }

  cancelAppearTo() {
    if (this._tweenOpacity) {
      this._tweenOpacity.kill();
      this._tweenOpacity = undefined;
    }
  }

  createAppearTimeline() {
    this._tweenOpacity = gsap.timeline({
      onComplete: () => (this._tweenOpacity = undefined),
    });
  }

  addAppearTo(duration: number, delay: number, opacity: number, ease?: string) {
    if (this._tweenOpacity) {
      this._tweenOpacity.to(this._cardMaterial, {
        duration: duration,
        delay: delay,
        opacity: opacity,
        ease: ease,
      });
    }
  }

  get card() {
    return this._card;
  }

  get cardMesh() {
    return this._cardMesh;
  }
}
