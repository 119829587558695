import * as THREE from "three";
import { PI2, PI_HALF, shuffleArray } from "../../lib/utils";

export const generateCircleFormations = (
  numOfItems: number,
  circleRadius: number,
  circleOffsetY: number
): THREE.Object3D[] => {
  const formations: THREE.Object3D[] = [];

  let radian = 0;
  const addRadian = PI2 / numOfItems;

  for (let i = 0; i < numOfItems; i++) {
    const object = new THREE.Object3D();

    const x = circleRadius * Math.sin(radian);
    const y = circleOffsetY;
    const z = circleRadius * Math.cos(radian);
    object.position.set(x, y, z);

    const lookingX = x * 2;
    const lookingY = circleOffsetY;
    const lookingZ = z * 2;
    object.lookAt(lookingX, lookingY, lookingZ);

    formations.push(object);

    radian += addRadian;
  }

  return formations;
};

/**
 * TableFormationsを生成する
 */
export function generateTableFormations(
  numOfItems: number,
  spaceX: number,
  spaceZ: number,
  centerPos: THREE.Vector3,
  random: boolean = false
): THREE.Object3D[] {
  const formations: THREE.Object3D[] = [];

  const numOfSide = Math.ceil(Math.sqrt(numOfItems));

  const offsetX = (spaceX * (numOfSide - 1)) / 2;
  const offsetZ = (spaceZ * (numOfSide - 1)) / 2;

  for (let i = 0; i < numOfItems; i++) {
    const object = new THREE.Object3D();

    object.position.x = centerPos.x + (i % numOfSide) * spaceX - offsetX;
    object.position.y = centerPos.y;
    object.position.z =
      centerPos.z + Math.floor(i / numOfSide) * spaceZ - offsetZ;

    object.lookAt(object.position.x, object.position.y + 1, object.position.z);

    formations.push(object);
  }

  if (random) {
    const shuffledFormations = shuffleArray(formations);
    return shuffledFormations;
  }

  return formations;
}

/**
 * Objectを均等でランダムに配置したを生成する
 */
export const generateRandomFormations = (
  numOfItems: number,
  distance: number,
  minDistance: number,
  centerPos: THREE.Vector3,
  offset: number
): THREE.Object3D[] => {
  const formations: THREE.Object3D[] = [];

  const posArrayXY: THREE.Vector2[] = [];
  posArrayXY.push(new THREE.Vector2(centerPos.x, centerPos.y));

  while (posArrayXY.length < numOfItems + 1) {
    // できるだけ中心に近い位置を選ぶ
    const maxIndex = Math.ceil(
      posArrayXY.length * Math.cos(Math.random() * PI_HALF)
    );

    // 座標を一つピックアップ
    const index = Math.floor(Math.random() * maxIndex);
    const basicPos = posArrayXY[index];

    // その座標からランダムな距離で座標を決める
    const radian = Math.random() * PI2;
    const pos = new THREE.Vector2(
      basicPos.x + Math.sin(radian) * distance,
      basicPos.y + Math.cos(radian) * distance
    );

    // その座標が他の座標と近すぎないかチェックする
    const isClose = posArrayXY.some((posXY) => {
      const distance = pos.distanceTo(posXY);
      return distance < minDistance;
    });

    // その座標が他の座標と適度な距離を持っていた場合、追加する
    if (!isClose) {
      posArrayXY.push(pos);
    }
  }

  // ダミーで配置した中央点を削除
  posArrayXY.shift();

  for (let i = 0; i < numOfItems; i++) {
    const object = new THREE.Object3D();

    object.position.x = posArrayXY[i].x;
    object.position.y = offset;
    object.position.z = posArrayXY[i].y;

    object.lookAt(object.position.x, object.position.y + 1, object.position.z);

    formations.push(object);
  }

  return formations;
};

/**
 * 球体フォーメーションを作成
 */
export function generateSphereFormations(
  numOfItems: number,
  radiusRate: number = 0.07
): THREE.Object3D[] {
  const formations: THREE.Object3D[] = [];

  const radius = numOfItems * radiusRate;
  const vector = new THREE.Vector3();

  for (let i = 1, l = numOfItems + 1; i < l; i++) {
    const phi = Math.acos(-1 + (2 * i) / l);
    const theta = Math.sqrt(l * Math.PI) * phi;

    const object = new THREE.Object3D();

    object.position.setFromSphericalCoords(radius, phi, theta);
    vector.copy(object.position).multiplyScalar(2);
    object.lookAt(vector);

    formations.push(object);
  }
  return formations;
}
