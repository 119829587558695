import { TickerCallback } from "./Ticker";

export class TickerListener<T = any> {
  public priority: number;
  public next: TickerListener | null = null;
  public previous: TickerListener | null = null;

  private fn: TickerCallback<T> | null;
  private context: T | null;
  private _destroyed = false;

  constructor(
    fn: TickerCallback<T> | null,
    context: T | null = null,
    priority = 0
  ) {
    this.fn = fn;
    this.context = context;
    this.priority = priority;
  }

  match(fn: TickerCallback<T>, context: any = null): boolean {
    return this.fn === fn && this.context === context;
  }

  emit(deltaTime: number): TickerListener | null {
    if (this.fn) {
      if (this.context) {
        this.fn.call(this.context, deltaTime);
      } else {
        (this as any).fn(deltaTime);
      }
    }

    const redirect = this.next;

    if (this._destroyed) {
      this.next = null;
    }

    return redirect;
  }

  connect(previous: TickerListener): void {
    this.previous = previous;
    if (previous.next) {
      previous.next.previous = this;
    }
    this.next = previous.next;
    previous.next = this;
  }

  destroy(hard = false): TickerListener | null {
    this._destroyed = true;
    this.fn = null;
    this.context = null;

    if (this.previous) {
      this.previous.next = this.next;
    }

    if (this.next) {
      this.next.previous = this.previous;
    }

    const redirect = this.next;

    this.next = hard ? null : redirect;
    this.previous = null;

    return redirect;
  }
}
