import { GUI } from "dat.gui";
import gsap from "gsap";
import * as THREE from "three";
import { Vector3 } from "three";
import { LightOrderTypes } from "../interfaces";
import {
  IS_DEBUG,
  LIGHT_DIRECT_COLOR,
  LIGHT_DIRECT_INTENSITY,
} from "../lib/configs";
import { TDebug } from "./TDebug";

export class TDirectLight {
  private static guiLightFolder?: GUI;
  private _light: THREE.DirectionalLight;
  private _lightHelper?: THREE.DirectionalLightHelper;
  private _tweenPos?: gsap.core.Timeline;
  private _tweenTargetPos?: gsap.core.Timeline;

  constructor(pos: Vector3, tarPos: Vector3, helperColor: number) {
    this._light = new THREE.DirectionalLight(LIGHT_DIRECT_COLOR);
    this._light.intensity = LIGHT_DIRECT_INTENSITY;

    this._light.position.copy(pos);
    this._light.target.position.copy(tarPos);
    this._light.target.updateMatrixWorld();

    if (IS_DEBUG) {
      this._lightHelper = new THREE.DirectionalLightHelper(
        this._light,
        2,
        helperColor
      );

      this.setupDebugGui();
    }
  }

  private setupDebugGui() {
    if (!TDirectLight.guiLightFolder) {
      const gui = TDebug.gui;
      TDirectLight.guiLightFolder = gui.addFolder("DirectLight");
    }

    const folder = TDirectLight.guiLightFolder;
    folder.add(this._light.color, "r", 0, 1);
    folder.add(this._light.color, "g", 0, 1);
    folder.add(this._light.color, "b", 0, 1);
    folder.add(this._light.position, "x", -50, 50);
    folder.add(this._light.position, "y", -50, 50);
    folder.add(this._light.position, "z", -50, 50);
    folder.add(this._light, "intensity", 0, 5);
  }

  update() {
    if (IS_DEBUG) {
      if (this._lightHelper) {
        this._lightHelper.update();
      }
    }
  }

  private createPosTimeline() {
    this._tweenPos = gsap.timeline({
      onComplete: () => (this._tweenPos = undefined),
    });
  }

  private cancelPositionTo() {
    if (this._tweenPos) {
      this._tweenPos.kill();
    }
  }

  private addPositionTo(
    duration: number,
    delay: number,
    targetPos: THREE.Vector3,
    ease?: string
  ) {
    if (IS_DEBUG) {
      console.log("TDirectLight: 移動命令", { duration, targetPos });
    }

    if (this._tweenPos) {
      this._tweenPos.to(this._light.position, {
        duration: duration,
        delay: delay,
        x: targetPos.x,
        y: targetPos.y,
        z: targetPos.z,
        ease: ease,
      });
    }
  }

  private createTargetTimeline() {
    this._tweenTargetPos = gsap.timeline({
      onComplete: () => {
        this._tweenTargetPos = undefined;
      },
    });
  }

  private cancelTargetTo() {
    if (this._tweenTargetPos) {
      this._tweenTargetPos.kill();
    }
  }

  private addTargetTo(
    duration: number,
    delay: number,
    targetLookPos: THREE.Vector3,
    ease?: string
  ) {
    if (IS_DEBUG) {
      console.log("TDirectLight: ターゲット移動", { duration, targetLookPos });
    }

    if (this._tweenTargetPos) {
      this._tweenTargetPos.to(this._light.target.position, {
        duration: duration,
        delay: delay,
        x: targetLookPos.x,
        y: targetLookPos.y,
        z: targetLookPos.z,
        ease: ease,
        onUpdate: () => {
          this._light.target.updateMatrixWorld();
        },
      });
    }
  }

  cancelMoveTo() {
    this.cancelPositionTo();
    this.cancelTargetTo();
  }

  createMoveTimelines() {
    this.createPosTimeline();
    this.createTargetTimeline();
  }

  addMoveTo(
    duration: number,
    delay: number,
    targetPos: THREE.Vector3,
    targetLookPos: THREE.Vector3,
    ease?: string
  ) {
    this.addPositionTo(duration, delay, targetPos, ease);
    this.addTargetTo(duration, delay, targetLookPos, ease);
  }

  orderMoves(orders: LightOrderTypes[]) {
    orders.forEach((order) => {
      this.addMoveTo(
        order.dur,
        order.delay,
        order.pos,
        order.tarPos,
        order.ease
      );
    });
  }

  attachAll(scene: THREE.Scene) {
    scene.add(this._light);

    if (IS_DEBUG) {
      if (this._lightHelper) {
        scene.add(this._lightHelper);
      }
    }
  }
}
