import { FC } from "react";

export const ContactBackLayer = () => {
  return null;
};

const Address: FC<{ title: string; value: string }> = ({ title, value }) => {
  return (
    <address className="font-sans not-italic">
      <div className="text-sm text-stone-500">{title}</div>
      <div className="text-xl indent-px tracking-wide">{value}</div>
    </address>
  );
};

export const Contact = () => {
  return (
    <div className="fixed z-10 inset-0 flex justify-center items-center">
      <div className="grid md:grid-cols-2 gap-x-24 gap-y-2 mt-80">
        <Address title="Email" value="info@ard-online.jp" />
        <Address title="TEL" value="03-6279-4418" />
      </div>
    </div>
  );
};
