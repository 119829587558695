import React, { Dispatch, SetStateAction } from "react";
import { animated, useSpring, useTransition } from "react-spring";
import { useAppSelector } from "../../store/storeHooks";
import styles from "./Menu.module.scss";

type Props = {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  setIsHover: Dispatch<SetStateAction<boolean>>;
};

export const MenuButton: React.FC<Props> = ({
  isOpen,
  setIsOpen,
  setIsHover,
}) => {
  const { isFinishedIntro } = useAppSelector((state) => state.loading);

  const animeStyles = useSpring({
    opacity: isFinishedIntro ? 1 : 0,
    delay: 200,
  });

  const textTransitions = useTransition(!isOpen, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  });

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const buttonCN = isOpen
    ? `${styles.button} ${styles.isOpen}`
    : `${styles.button}`;

  if (!isFinishedIntro) return null;

  return (
    <animated.button
      className={`absolute z-40 top-0 right-0 w-24 h-16 sm:w-32 sm:h-18 pointer-events-auto ${buttonCN}`}
      style={animeStyles}
      onClick={toggleMenu}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
    >
      <div className="flex justify-center items-center pr-0 pb-0 sm:pr-9 sm:pb-2 w-full h-full">
        <div className="hidden sm:block relative w-12 h-7 text-lg mr-1 text-white">
          {textTransitions(
            (textStyles, item) =>
              item && (
                <animated.span
                  className="absolute block inset-0"
                  style={textStyles}
                >
                  Menu
                </animated.span>
              )
          )}
        </div>
        <div className={`${styles.buttonLines}`}>
          <div className={styles.buttonLine}></div>
          <div className={styles.buttonLine}></div>
          <div className={styles.buttonLine}></div>
        </div>
      </div>
    </animated.button>
  );
};
