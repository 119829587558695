import gsap from "gsap";
import * as THREE from "three";
import { MathUtils } from "three";
import {
  IS_DEBUG,
  LOGO_BLACK_FADE_DELAY,
  LOGO_BLACK_FADE_DUR,
  LOGO_BLACK_FADE_SHORT_DELAY,
  LOGO_EASE,
  LOGO_LINE_COLOR,
  LOGO_LOADING_POS,
  LOGO_SIZE,
  LOGO_WHITE_FADE_DELAY,
  LOGO_WHITE_FADE_DUR,
  LOGO_WHITE_FADE_SHORT_DELAY,
} from "../lib/configs";
import { MODE, ModeTypes } from "../lib/const";
import { PI_HALF } from "../lib/utils";
import { store } from "../store/store";
import { TLoader } from "./TLoader";

const IS_PLANE_HELPER = IS_DEBUG && false;

const LOGO_HALF_SIZE = LOGO_SIZE * 0.5;
const LOGO_OFFSET_Z = 0.0001;

const LOGO_WHITE_IMAGE_PATH = "./images/textures/logo_texture.png";
const LOGO_BLACK_IMAGE_PATH = "./images/textures/logo_black_texture.png";

const PLANE_NORMAL_VEC = new THREE.Vector3(0, 0, -1);
const PLANE_CONSTANT = LOGO_SIZE * 0.5;
const PLANE_CONSTANT_TO = -LOGO_SIZE * 0.5;

class Logo {
  private _mesh: THREE.Mesh;
  private _localPlane: THREE.Plane;
  private _helper?: THREE.PlaneHelper;
  private _hideAnime?: gsap.core.Tween;

  constructor(imagePath: string) {
    const loader = TLoader.loader;
    const texture = loader.load(imagePath);

    this._localPlane = new THREE.Plane(PLANE_NORMAL_VEC, PLANE_CONSTANT);

    const material = new THREE.MeshBasicMaterial({
      map: texture,
      color: 0xffffff,
      side: THREE.DoubleSide,
      clippingPlanes: [this._localPlane],
    });

    const geometry = new THREE.PlaneGeometry(LOGO_SIZE, LOGO_SIZE);
    this._mesh = new THREE.Mesh(geometry, material);

    if (IS_DEBUG) {
      const planeHelper = new THREE.PlaneHelper(this._localPlane, 10, 0xffff00);
      this._helper = planeHelper;
    }
  }

  hide(duration: number, delay: number) {
    if (this._hideAnime) {
      this._hideAnime.kill();
    }

    this._hideAnime = gsap.to(this._localPlane, {
      duration: duration,
      delay: delay,
      constant: PLANE_CONSTANT_TO,
      ease: LOGO_EASE,
    });
  }

  get mesh(): THREE.Mesh {
    return this._mesh;
  }

  get helper(): THREE.PlaneHelper | undefined {
    return this._helper;
  }
}

/**
 * -----------------------------------
 * LogoBorder
 * -----------------------------------
 */
class LogoBorder {
  private _lineTop: THREE.Line;
  private _lineBottom: THREE.Line;
  private _lineLeft: THREE.Line;
  private _lineRight: THREE.Line;
  private _isTopCompleted = false;
  private _isBottomCompleted = false;
  private _isLeftCompleted = false;
  private _isRightCompleted = false;
  private _isLoaded: boolean = false;
  private _tweenHide?: gsap.core.Tween;

  constructor() {
    const pointsTop = [];
    pointsTop.push(new THREE.Vector3(LOGO_HALF_SIZE, LOGO_HALF_SIZE, 0));
    pointsTop.push(new THREE.Vector3(LOGO_HALF_SIZE, LOGO_HALF_SIZE, 0));

    const pointsBottom = [];
    pointsBottom.push(new THREE.Vector3(-LOGO_HALF_SIZE, -LOGO_HALF_SIZE, 0));
    pointsBottom.push(new THREE.Vector3(-LOGO_HALF_SIZE, -LOGO_HALF_SIZE, 0));

    const pointsLeft = [];
    pointsLeft.push(new THREE.Vector3(-LOGO_HALF_SIZE, LOGO_HALF_SIZE, 0));
    pointsLeft.push(new THREE.Vector3(-LOGO_HALF_SIZE, LOGO_HALF_SIZE, 0));

    const pointsRight = [];
    pointsRight.push(new THREE.Vector3(LOGO_HALF_SIZE, -LOGO_HALF_SIZE, 0));
    pointsRight.push(new THREE.Vector3(LOGO_HALF_SIZE, -LOGO_HALF_SIZE, 0));

    const lineTopGeometry = new THREE.BufferGeometry().setFromPoints(pointsTop);
    const lineBottomGeometry = new THREE.BufferGeometry().setFromPoints(
      pointsBottom
    );
    const lineLeftGeometry = new THREE.BufferGeometry().setFromPoints(
      pointsLeft
    );
    const lineRightGeometry = new THREE.BufferGeometry().setFromPoints(
      pointsRight
    );

    const lineMaterila = new THREE.LineBasicMaterial({
      color: LOGO_LINE_COLOR,
    });

    this._lineTop = new THREE.Line(lineTopGeometry, lineMaterila);
    this._lineBottom = new THREE.Line(lineBottomGeometry, lineMaterila);
    this._lineLeft = new THREE.Line(lineLeftGeometry, lineMaterila);
    this._lineRight = new THREE.Line(lineRightGeometry, lineMaterila);
  }

  private drowLine(progress: number) {
    if (progress < 0.25) {
      const scale = MathUtils.clamp(progress * 4, 0, 1);
      const pos: any = this._lineLeft.geometry.getAttribute("position").array;
      pos[4] = LOGO_HALF_SIZE - LOGO_SIZE * scale;

      this._lineLeft.geometry.setAttribute(
        "position",
        new THREE.BufferAttribute(pos, 3)
      );
    } else if (progress < 0.5) {
      const scale = MathUtils.clamp((progress - 0.25) * 4, 0, 1);
      const pos: any = this._lineBottom.geometry.getAttribute("position").array;
      pos[3] = -LOGO_HALF_SIZE + LOGO_SIZE * scale;

      this._lineBottom.geometry.setAttribute(
        "position",
        new THREE.BufferAttribute(pos, 3)
      );
    } else if (progress < 0.75) {
      const scale = MathUtils.clamp((progress - 0.5) * 4, 0, 1);
      const pos: any = this._lineRight.geometry.getAttribute("position").array;
      pos[4] = -LOGO_HALF_SIZE + LOGO_SIZE * scale;

      this._lineRight.geometry.setAttribute(
        "position",
        new THREE.BufferAttribute(pos, 3)
      );
    } else {
      const scale = MathUtils.clamp((progress - 0.75) * 4, 0, 1);
      const pos: any = this._lineTop.geometry.getAttribute("position").array;
      pos[3] = LOGO_HALF_SIZE - LOGO_SIZE * scale;

      this._lineTop.geometry.setAttribute(
        "position",
        new THREE.BufferAttribute(pos, 3)
      );
    }
  }

  private checkCompletedAndFixLine(progress: number) {
    if (progress > 0.25) {
      if (!this._isLeftCompleted) {
        this._isLeftCompleted = true;

        const pos: any = this._lineLeft.geometry.getAttribute("position").array;
        pos[4] = -LOGO_HALF_SIZE;
        this._lineLeft.geometry.setAttribute(
          "position",
          new THREE.BufferAttribute(pos, 3)
        );
      }
    }

    if (progress > 0.5) {
      if (!this._isBottomCompleted) {
        this._isBottomCompleted = true;

        const pos: any =
          this._lineBottom.geometry.getAttribute("position").array;
        pos[3] = LOGO_HALF_SIZE;
        this._lineBottom.geometry.setAttribute(
          "position",
          new THREE.BufferAttribute(pos, 3)
        );
      }
    }

    if (progress > 0.75) {
      if (!this._isRightCompleted) {
        this._isRightCompleted = true;

        const pos: any =
          this._lineRight.geometry.getAttribute("position").array;
        pos[4] = LOGO_HALF_SIZE;
        this._lineRight.geometry.setAttribute(
          "position",
          new THREE.BufferAttribute(pos, 3)
        );
      }
    }

    if (progress >= 1.0) {
      if (!this._isTopCompleted) {
        this._isTopCompleted = true;

        const pos: any = this._lineTop.geometry.getAttribute("position").array;
        pos[3] = -LOGO_HALF_SIZE;
        this._lineTop.geometry.setAttribute(
          "position",
          new THREE.BufferAttribute(pos, 3)
        );
      }

      this._isLoaded = true;
    }
  }

  private lineUpdate() {
    const { progress } = store.getState().loading;

    this.drowLine(progress);
    this.checkCompletedAndFixLine(progress);
  }

  update(deltaTime: number) {
    if (this._isLoaded) return;

    this.lineUpdate();
  }

  hide(delay: number) {
    if (this._tweenHide) {
      this._tweenHide.kill();
    }

    this._tweenHide = gsap.to(
      {},
      {
        duration: 0.0,
        delay: delay,
        onComplete: () => {
          this._lineTop.visible = false;
          this._lineBottom.visible = false;
          this._lineLeft.visible = false;
          this._lineRight.visible = false;
        },
      }
    );
  }

  get lineTop(): THREE.Line {
    return this._lineTop;
  }

  get lineBottom(): THREE.Line {
    return this._lineBottom;
  }

  get lineLeft(): THREE.Line {
    return this._lineLeft;
  }

  get lineRight(): THREE.Line {
    return this._lineRight;
  }
}

/**
 * -----------------------------------
 * TLogo
 * -----------------------------------
 */
export class TLogo {
  private _logoGroup: THREE.Group;
  private _whiteLogo: Logo;
  private _blackLogo: Logo;
  private _logoBorder: LogoBorder;

  constructor() {
    this._whiteLogo = new Logo(LOGO_WHITE_IMAGE_PATH);
    this._blackLogo = new Logo(LOGO_BLACK_IMAGE_PATH);
    this._logoBorder = new LogoBorder();

    this._whiteLogo.mesh.position.z = -LOGO_OFFSET_Z;
    this._blackLogo.mesh.position.z = -LOGO_OFFSET_Z * 2;

    this._logoGroup = new THREE.Group();
    this._logoGroup.add(this._whiteLogo.mesh);
    this._logoGroup.add(this._blackLogo.mesh);
    this._logoGroup.add(this._logoBorder.lineTop);
    this._logoGroup.add(this._logoBorder.lineBottom);
    this._logoGroup.add(this._logoBorder.lineLeft);
    this._logoGroup.add(this._logoBorder.lineRight);

    this._logoGroup.rotation.x = -PI_HALF;
    this._logoGroup.position.copy(LOGO_LOADING_POS);
  }

  update(deltaTime: number) {
    this._logoBorder.update(deltaTime);
  }

  attachAll(scene: THREE.Scene) {
    scene.add(this._logoGroup);

    if (IS_PLANE_HELPER) {
      const helper = this._whiteLogo.helper;
      if (helper) {
        scene.add(helper);
      }
    }
  }

  changeMode(mode: ModeTypes) {
    const isFinishedIntro = store.getState().loading.isFinishedIntro;

    if (!isFinishedIntro) {
      if (mode === MODE.HOME) {
        this._whiteLogo.hide(LOGO_WHITE_FADE_DUR, LOGO_WHITE_FADE_DELAY);
        this._blackLogo.hide(LOGO_BLACK_FADE_DUR, LOGO_BLACK_FADE_DELAY);
        this._logoBorder.hide(LOGO_WHITE_FADE_DELAY + LOGO_WHITE_FADE_DUR);
      } else {
        this._whiteLogo.hide(LOGO_WHITE_FADE_DUR, LOGO_WHITE_FADE_SHORT_DELAY);
        this._blackLogo.hide(LOGO_BLACK_FADE_DUR, LOGO_BLACK_FADE_SHORT_DELAY);
        this._logoBorder.hide(
          LOGO_WHITE_FADE_SHORT_DELAY + LOGO_WHITE_FADE_DUR
        );
      }
    }
  }
}
