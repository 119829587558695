import {
  Dispatch,
  SetStateAction,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { animated, config, useSpring } from "react-spring";
import { SITE_PATH } from "../../lib/const";
import { useAppSelector } from "../../store/storeHooks";
import styles from "./Menu.module.scss";
import { MenuItem } from "./MenuItem";

type Props = {
  isOpen: boolean;
  isHover: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
};

export const MenuList: React.FC<Props> = ({ isOpen, isHover, setIsOpen }) => {
  const { isFinishedIntro } = useAppSelector((state) => state.loading);
  const [navSize, setNavSize] = useState({ width: 9999, height: 9999 });
  const navOuterElement = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    const resize = () => {
      if (navOuterElement.current) {
        setNavSize({
          width: navOuterElement.current.offsetWidth,
          height: navOuterElement.current.offsetHeight,
        });
      }
    };

    window.setTimeout(() => {
      resize();
    }, 1000);

    window.addEventListener("resize", resize);

    return () => {
      window.removeEventListener("resize", resize);
    };
  }, []);

  const isSm = window.innerWidth >= 640;

  const widthShown = isSm ? 112 : 68;
  const heightShown = isSm ? 72 : 64;
  const offsetX = 320;
  const hoverSlidePxX = isSm ? 3 : 0;
  const hoverSlidePxY = isSm ? 6 : 0;

  let translateX = `${navSize.width - offsetX}px`;
  if (isFinishedIntro) {
    if (isOpen) {
      translateX = "0px";
    } else {
      if (isHover) {
        translateX = `${
          navSize.width - widthShown - offsetX - hoverSlidePxX
        }px`;
      } else {
        translateX = `${navSize.width - widthShown - offsetX}px`;
      }
    }
  }

  let translateY = `-${navSize.height}px`;
  if (isFinishedIntro) {
    if (isOpen) {
      translateY = "0px";
    } else {
      if (isHover) {
        translateY = `-${navSize.height - heightShown - hoverSlidePxY}px`;
      } else {
        translateY = `-${navSize.height - heightShown}px`;
      }
    }
  }

  const navWrapperStyles = useSpring({
    from: {
      translateX: `9999px`,
      translateY: `-9999px`,
    },
    to: {
      translateX: translateX,
      translateY: translateY,
    },
    config: config.default,
  });

  const rotateDeg = isSm ? 20 : 15;
  const hoverRotateDeg = isSm ? 2 : 0;

  let rotateZ = `-${rotateDeg}deg`;
  if (isOpen) {
    rotateZ = "0deg";
  } else {
    if (isHover) rotateZ = `-${rotateDeg + hoverRotateDeg}deg`;
  }

  const navStyles = useSpring({
    rotateZ: rotateZ,
  });

  const ulStyles = {
    opacity: !isOpen ? 0 : 1,
  };

  const handleClick = () => {
    setIsOpen(false);
  };

  return (
    <animated.div
      className={`absolute z-20 inset-y-0 max-w-md h-full pointer-events-auto ${styles.navWrapper}`}
      style={navWrapperStyles}
      ref={navOuterElement}
    >
      <animated.nav
        className="w-full h-full bg-black origin-bottom-left"
        style={navStyles}
      >
        <ul
          className={`pt-20 sm:pt-28 max-h-full overflow-x-hidden overflow-y-auto text-white ${styles.navList}`}
          style={ulStyles}
        >
          <MenuItem
            to={SITE_PATH.HOME}
            label="Home"
            onClick={() => handleClick()}
          />
          <MenuItem
            to={SITE_PATH.ABOUT}
            label="About"
            onClick={() => handleClick()}
          />
          <MenuItem
            to={SITE_PATH.SERVICE}
            label="Service"
            onClick={() => handleClick()}
          />
          <MenuItem
            to={SITE_PATH.TEAM}
            label="Team"
            onClick={() => handleClick()}
          />
          <MenuItem
            to={SITE_PATH.COMPANY}
            label="Company"
            onClick={() => handleClick()}
          />
          <MenuItem
            to={SITE_PATH.CONTACT}
            label="Contact"
            onClick={() => handleClick()}
          />
        </ul>
      </animated.nav>
    </animated.div>
  );
};
