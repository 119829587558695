export const SITE_PATH = {
  HOME: "/",
  ABOUT: "/about",
  COMPANY: "/company",
  CONTACT: "/contact",
  SERVICE: "/service",
  TEAM: "/team",
};
export type SitePathTypes = typeof SITE_PATH[keyof typeof SITE_PATH];

const SITE_TITLE = "株式会社庄田竜事務所";

export const PAGE_TITLE = {
  HOME: `${SITE_TITLE}`,
  ABOUT: `About | ${SITE_TITLE}`,
  COMPANY: `Company | ${SITE_TITLE}`,
  CONTACT: `Contact | ${SITE_TITLE}`,
  SERVICE: `Service | ${SITE_TITLE}`,
  TEAM: `Team |  ${SITE_TITLE}`,
};

export const MODE = {
  LOADING: "loading",
  HOME: "home",
  ABOUT: "about",
  SERVICE: "service",
  FAR: "far",
  FRONT: "front",
  SPHERE: "sphere",
};
export type ModeTypes = typeof MODE[keyof typeof MODE];
