import { TickerListener } from "./TickerListener";

const TARGET_FPMS = 0.06;

export type TickerCallback<T> = (this: T, dt: number) => any;

export class Ticker {
  private static _shared: Ticker;
  public deltaTime = 1;
  public deltaMS: number;
  public elapsedMS: number;
  public lastTime = -1;
  public started = false;
  private _head: TickerListener;
  private _requestId: number | null = null;
  private _maxElapsedMS = 100;
  private _minElapsedMS = 0;
  private _lastFrame = -1;
  private _tick: (time: number) => any;

  constructor() {
    this._head = new TickerListener(null, null);
    this.deltaMS = 1 / TARGET_FPMS;
    this.elapsedMS = 1 / TARGET_FPMS;

    this._tick = (time: number): void => {
      this._requestId = null;

      if (this.started) {
        this.update(time);

        if (this.started && this._requestId === null && this._head.next) {
          this._requestId = requestAnimationFrame(this._tick);
        }
      }
    };
  }

  private _requestIfNeeded(): void {
    if (this._requestId === null && this._head.next) {
      this.lastTime = performance.now();
      this._lastFrame = this.lastTime;

      this._requestId = requestAnimationFrame(this._tick);
    }
  }

  private _cancelIfNeeded(): void {
    if (this._requestId !== null) {
      cancelAnimationFrame(this._requestId);
      this._requestId = null;
    }
  }

  private _startIfPossible(): void {
    if (this.started) {
      this._requestIfNeeded();
    }
  }

  add<T = any>(fn: TickerCallback<T>, context?: T, priority = 10): this {
    return this._addListener(new TickerListener(fn, context, priority));
  }

  private _addListener(listener: TickerListener): this {
    let current = this._head.next;
    let previous = this._head;

    if (!current) {
      listener.connect(previous);
    } else {
      while (current) {
        if (listener.priority > current.priority) {
          listener.connect(previous);
          break;
        }

        previous = current;
        current = current.next;
      }

      if (!listener.previous) {
        listener.connect(previous);
      }
    }

    this._startIfPossible();

    return this;
  }

  remove<T = any>(fn: TickerCallback<T>, context?: T): this {
    let listener = this._head.next;

    while (listener) {
      if (listener.match(fn, context)) {
        listener = listener.destroy();
      } else {
        listener = listener.next;
      }
    }

    if (!this._head.next) {
      this._cancelIfNeeded();
    }

    return this;
  }

  get count(): number {
    if (!this._head) {
      return 0;
    }

    let count = 0;
    let current = this._head;

    while (current.next) {
      current = current.next;
      count++;
    }

    return count;
  }

  start(): void {
    if (!this.started) {
      this.started = true;
      this._requestIfNeeded();
    }
  }

  stop(): void {
    if (this.started) {
      this.started = false;
      this._cancelIfNeeded();
    }
  }

  update(currentTime = performance.now()): void {
    let elapsedMS;

    if (currentTime > this.lastTime) {
      elapsedMS = this.elapsedMS = currentTime - this.lastTime;

      if (elapsedMS > this._maxElapsedMS) {
        elapsedMS = this._maxElapsedMS;
      }

      if (this._minElapsedMS) {
        const delta = (currentTime - this._lastFrame) | 0;

        if (delta < this._minElapsedMS) {
          return;
        }

        this._lastFrame = currentTime - (delta % this._minElapsedMS);
      }

      this.deltaMS = elapsedMS;
      this.deltaTime = this.deltaMS * TARGET_FPMS;

      const head = this._head;

      let listener = head.next;
      while (listener) {
        listener = listener.emit(this.deltaTime);
      }

      if (!head.next) {
        this._cancelIfNeeded();
      }
    } else {
      this.deltaTime = this.deltaMS = this.elapsedMS = 0;
    }

    this.lastTime = currentTime;
  }

  get FPS(): number {
    return 1000 / this.elapsedMS;
  }

  get minFPS(): number {
    return 1000 / this._maxElapsedMS;
  }

  set minFPS(fps: number) {
    const minFPS = Math.min(this.maxFPS, fps);
    const minFPMS = Math.min(Math.max(0, minFPS) / 1000, TARGET_FPMS);

    this._maxElapsedMS = 1 / minFPMS;
  }

  get maxFPS(): number {
    if (this._minElapsedMS) {
      return Math.round(1000 / this._minElapsedMS);
    }

    return 0;
  }

  set maxFPS(fps: number) {
    if (fps === 0) {
      this._minElapsedMS = 0;
    } else {
      const maxFPS = Math.max(this.minFPS, fps);
      this._minElapsedMS = 1 / (maxFPS / 1000);
    }
  }

  static get shared(): Ticker {
    if (!Ticker._shared) {
      Ticker._shared = new Ticker();
    }

    return Ticker._shared;
  }
}
