import gsap from "gsap";
import * as THREE from "three";
import { CARD_SIZE_HALF, IS_DEBUG } from "../lib/configs";

const fadeDuration = 0.3;
const isDebugBg = false && IS_DEBUG;

export class TText {
  private _text: THREE.Mesh;
  private _material: THREE.MeshBasicMaterial;
  private _tween?: gsap.core.Timeline;

  constructor(text: string) {
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d")!;

    // measureTextするためいったん設定
    const textAlign = "left";
    const textBaseline = "middle";
    const fontSizeSmall = 14;
    const fontSize = 20;
    const fontColor = "rgba(0, 0, 0, 1.0)";
    const fontSmall = `bold ${fontSizeSmall}px sans-serif`;
    const font = `bold ${fontSize}px sans-serif`;
    const lineWidthSmall = 0.7;
    const lineWidth = 1;
    const strokeStyle = "rgba(250,250,249, 0.25)";

    ctx.font = font;
    ctx.textAlign = textAlign;
    ctx.textBaseline = textBaseline;
    ctx.fillStyle = fontColor;
    ctx.lineWidth = lineWidth;

    const minWidth = fontSize * 10;
    const textWidth = Math.max(minWidth, ctx.measureText(text).width);

    // 幅を指定
    const indent = 2;
    const lineHieghtSmall = fontSizeSmall * 1.4;
    const lineHieght = fontSize * 1.4;

    canvas.width = textWidth + indent * 2;
    canvas.height = lineHieghtSmall + lineHieght;

    // 中央にテキストを描画
    ctx.textAlign = textAlign;
    ctx.textBaseline = textBaseline;
    ctx.fillStyle = fontColor;
    ctx.strokeStyle = strokeStyle;

    ctx.font = fontSmall;
    ctx.lineWidth = lineWidthSmall;
    ctx.fillText("Philosophy", indent, lineHieghtSmall * 0.5);
    ctx.strokeText("Philosophy", indent, lineHieghtSmall * 0.5);

    ctx.font = font;
    ctx.lineWidth = lineWidth;
    ctx.fillText(text, indent, lineHieghtSmall + lineHieght * 0.5);
    ctx.strokeText(text, indent, lineHieghtSmall + lineHieght * 0.5);

    if (isDebugBg) {
      ctx.fillStyle = "rgba(255,0, 255, 0.2)";
      ctx.fillRect(0, 0, canvas.width, canvas.height);
    }

    const texture = new THREE.CanvasTexture(canvas);

    this._material = new THREE.MeshBasicMaterial({
      map: texture,
      side: THREE.DoubleSide,
      transparent: true,
      opacity: 0.0,
      alphaTest: 0.1,
    });

    const geoRate = 0.01;
    const geoSize = {
      width: canvas.width * geoRate,
      height: canvas.height * geoRate,
    };
    const geometry = new THREE.PlaneGeometry(geoSize.width, geoSize.height);
    this._text = new THREE.Mesh(geometry, this._material);

    const space = 0.1;
    this._text.position.x = CARD_SIZE_HALF + space + geoSize.width * 0.5;
  }

  private createTimeline() {
    this._tween = gsap.timeline({
      onComplete: () => (this._tween = undefined),
    });
  }

  private cancelTimeline() {
    if (this._tween) {
      this._tween.kill();
      this._tween = undefined;
    }
  }

  private addShowTo() {
    if (this._tween) {
      this._text.visible = true;

      this._tween.to(this._material, {
        duration: fadeDuration,
        opacity: 1,
        ease: "power1.in",
      });
    }
  }

  private addHideTo(delay: number) {
    if (this._tween) {
      this._tween.to(this._material, {
        duration: fadeDuration,
        delay: delay,
        opacity: 0,
        ease: "power1.out",
        onComplete: () => {
          this._text.visible = false;
        },
      });
    }
  }

  appear(delayHide: number = 0) {
    this.cancelTimeline();
    this.createTimeline();

    this.addShowTo();
    this.addHideTo(fadeDuration + delayHide);
  }

  hide() {
    this.cancelTimeline();
    this.createTimeline();

    this.addHideTo(0);
  }

  setPosY(y: number) {
    this._text.position.y = y;
  }

  get text() {
    return this._text;
  }
}
