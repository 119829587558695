import { Copyright } from "./components/atoms/Copyright";
import { SiteLogo } from "./components/atoms/SiteLogo";
import { Menu } from "./components/menu/Menu";
import { BackRouter } from "./components/router/BackRouter";
import { FrontRouter } from "./components/router/FrontRouter";
import { ThreeCanvas } from "./components/ThreeCanvas";
import { useTitleChanger } from "./hooks/useMetaChanger";
import { useUserVoices } from "./hooks/useUserVoices";

function App() {
  useTitleChanger();

  useUserVoices();

  return (
    <div className="App font-NotoSerif text-stone-900">
      <SiteLogo />

      <Menu />

      <Copyright />

      <BackRouter />

      <ThreeCanvas />

      <FrontRouter />
    </div>
  );
}

export default App;
