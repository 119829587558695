import { FC, ReactNode } from "react";

export const RedTitle: FC<{ children: ReactNode }> = ({ children }) => {
  return (
    <div className="relative">
      <div className="absolute top-1/2 -left-8 w-7 h-0.5 hidden md:block bg-rose-700" />
      <h1 className="text-xl font-sans italic text-rose-700">{children}</h1>
    </div>
  );
};

export const CatchText: FC<{ children: ReactNode }> = ({ children }) => {
  return <p className="mt-3">{children}</p>;
};

export const ContentTitle: FC<{ children: ReactNode; className?: string }> = ({
  children,
  className,
}) => {
  return (
    <h2 className={`text-3xl font-sans font-bold ${className}`}>{children}</h2>
  );
};

export const SinceText: FC<{ children: ReactNode }> = ({ children }) => {
  return <p className="mt-1 text-lg font-sans font-bold">{children}</p>;
};

export const Content: FC<{ children: ReactNode }> = ({ children }) => {
  return <div className="mt-4 prose-sm max-w-none">{children}</div>;
};

export const DList: FC<{ title: ReactNode; data: ReactNode }> = ({
  title,
  data,
}) => {
  return (
    <dl className="flex my-3 text-sm">
      <dt className="flex-none basis-28">{title}</dt>
      <dd className="">{data}</dd>
    </dl>
  );
};
