import * as THREE from "three";
import { Vector2 } from "three";
import { CAMERA_FAR, CAMERA_NEAR, IS_DEBUG } from "../lib/configs";
import { MODE, ModeTypes } from "../lib/const";
import { store } from "../store/store";
import { TCard } from "./TCard";
import { TCards } from "./TCards";

const isDebugMouseMove = IS_DEBUG && true;
const isDebugClick = IS_DEBUG && true;

/**
 * クリック関係の処理を管理するクラス
 */
export class TPointer {
  private camera: THREE.Camera;
  private canvasElement: HTMLCanvasElement;
  private tCards: TCards;
  private raycaster: THREE.Raycaster;
  private currentMode = MODE.LOADING;
  private isEnable = false;

  /**
   * Constructor
   */
  constructor(
    canvasElement: HTMLCanvasElement,
    camera: THREE.Camera,
    tCards: TCards
  ) {
    this.canvasElement = canvasElement;
    this.camera = camera;
    this.tCards = tCards;

    // Raycaster Settings
    this.raycaster = new THREE.Raycaster();
    this.raycaster.near = CAMERA_NEAR;
    this.raycaster.far = CAMERA_FAR;
    this.raycaster.layers.set(1);

    // add click listener
    this.canvasElement.addEventListener(
      "pointerdown",
      this.handleClick.bind(this)
    );
  }

  private handleClick(event: MouseEvent) {
    if (!this.isEnable) return;

    // 左クリック以外の場合、処理を進めない
    if (event.button !== 0) return;

    event.preventDefault();

    const point = this.computePointerPosition(event);
    const intersectTCard = this.getIntersectTCard(point, this.camera);

    // クリックされたカードがあれば、そのカードに動くよう命令
    if (intersectTCard) {
      if (isDebugClick) {
        console.log(`TPointer: カードがクリックされた`, intersectTCard);
      }

      this.queActTCard(intersectTCard);
    }
  }

  /**
   * ポインターの座標を計算する
   */
  private computePointerPosition(event: MouseEvent): Vector2 {
    const pointer = new THREE.Vector2();

    pointer.x =
      ((event.clientX - this.canvasElement.offsetLeft) /
        this.canvasElement.offsetWidth) *
        2 -
      1;
    pointer.y =
      -(
        (event.clientY - this.canvasElement.offsetTop) /
        this.canvasElement.offsetHeight
      ) *
        2 +
      1;

    if (isDebugMouseMove) {
      console.log(`TPointer: ポインタの位置を更新`, pointer);
    }

    return pointer;
  }

  /**
   * カードがクリックされたか判定し、クリックされたカードがあれば返す
   */
  private getIntersectTCard(
    point: THREE.Vector2,
    camera: THREE.Camera
  ): TCard | undefined {
    this.raycaster.setFromCamera(point, camera);

    // カードグループの中から、raychasterが一致するものがないか探す
    const intersectCardMeshs = this.raycaster.intersectObjects(
      this.tCards.cardsGroup.children,
      true
    );

    // 交差したobjmeshから、idを比較し、該当するtCardを取得する
    for (let i = 0; i < intersectCardMeshs.length; i++) {
      const intersectCardObj = intersectCardMeshs[i].object;

      if (intersectCardObj) {
        const tCard = this.tCards.getTCardByObjId(intersectCardObj.id);

        if (tCard) {
          return tCard;
        }
      }
    }

    return undefined;
  }

  /**
   * クリックされたカードに動くよう命令する
   */
  private queActTCard(tCard: TCard) {
    tCard.onClick();
  }

  private changeIsEnableByMode() {
    const isFinishedIntro = store.getState().loading.isFinishedIntro;

    if (isFinishedIntro && this.currentMode === MODE.HOME) {
      this.isEnable = true;
    } else {
      this.isEnable = false;
    }

    if (IS_DEBUG)
      console.log(`TPointer: IsEnableを変更`, {
        isEnable: this.isEnable,
        mode: this.currentMode,
      });
  }

  finishIntro() {
    this.changeIsEnableByMode();
  }

  changeMode(mode: ModeTypes) {
    this.currentMode = mode;

    this.changeIsEnableByMode();
  }
}
