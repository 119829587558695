import gsap from "gsap";
import * as THREE from "three";
import { CameraOrderTypes } from "../interfaces";
import {
  CAMERA_FAR,
  CAMERA_FAR_LOOK,
  CAMERA_FAR_POS,
  CAMERA_FOV,
  CAMERA_FOV_FLAT,
  CAMERA_FOV_WIDE,
  CAMERA_FRONT_LOOK,
  CAMERA_FRONT_POS,
  CAMERA_HOME_LOOK,
  CAMERA_HOME_POS,
  CAMERA_INTRO_ORDERS,
  CAMERA_INTRO_SHORT_ORDERS,
  CAMERA_LOADING_LOOK,
  CAMERA_LOADING_POS,
  CAMERA_NEAR,
  CAMERA_SIDE_LOOK,
  CAMERA_SIDE_POS,
  CAMERA_SINGLE_LOOK,
  CAMERA_SINGLE_POS,
  CAMERA_SPHERE_LOOK,
  CAMERA_SPHERE_POS,
  IS_DEBUG,
  TRANSITION_DELAY,
  TRANSITION_DURATION,
  TRANSITION_HALF,
} from "../lib/configs";
import { MODE, ModeTypes } from "../lib/const";
import { store } from "../store/store";
import { setupTCameraDebugGui } from "./debug/TCameraDebug";
import { TControls } from "./TControls";

export class TCamera {
  private _tControls: TControls;
  private _camera: THREE.PerspectiveCamera;
  private _tweenPos?: gsap.core.Timeline;
  private _tweenRotate?: gsap.core.Timeline;
  private _tweenFov?: gsap.core.Timeline;
  private _isRequestedUpdateProjectionMatrix: boolean = false;

  constructor(domElement: HTMLCanvasElement) {
    this._camera = new THREE.PerspectiveCamera(
      CAMERA_FOV,
      window.innerWidth / window.innerHeight,
      CAMERA_NEAR,
      CAMERA_FAR
    );

    this._tControls = new TControls(this._camera, domElement);
    this._tControls.resetTarget(CAMERA_LOADING_LOOK);

    this._camera.position.copy(CAMERA_LOADING_POS);
    this._camera.lookAt(CAMERA_LOADING_LOOK);

    if (IS_DEBUG) {
      setupTCameraDebugGui(this);
    }
  }

  private cancelPositionTo() {
    if (this._tweenPos) {
      this._tweenPos.kill();
    }
  }

  private createPosTimeline() {
    this._tweenPos = gsap.timeline({
      onComplete: () => {
        this._tweenPos = undefined;
      },
    });
  }

  private addPositionTo(
    duration: number,
    delay: number,
    targetPos: THREE.Vector3,
    ease?: string,
    isEnableControls: boolean = false
  ) {
    if (IS_DEBUG) console.log("TCamera: 移動命令", { duration, targetPos });

    if (this._tweenPos) {
      this._tweenPos.to(this._camera.position, {
        duration: duration,
        delay: delay,
        x: targetPos.x,
        y: targetPos.y,
        z: targetPos.z,
        ease: ease,
        onComplete: () => {
          if (isEnableControls) {
            this._tControls.enable();
          }
        },
      });
    }
  }

  private createRotateTimeline() {
    this._tweenRotate = gsap.timeline({
      onComplete: () => {
        this._tweenRotate = undefined;
      },
    });
  }

  private cancelRotateTo() {
    if (this._tweenRotate) {
      this._tweenRotate.kill();
    }
  }

  private addRotateToEuler(
    duration: number,
    delay: number,
    targetEuler: THREE.Euler,
    ease?: string
  ) {
    if (IS_DEBUG)
      console.log("TCamera: 回転命令byEuler", { duration, targetEuler });

    if (this._tweenRotate) {
      this._tweenRotate.to(this._camera.rotation, {
        duration: duration,
        delay: delay,
        x: targetEuler.x,
        y: targetEuler.y,
        z: targetEuler.z,
        ease,
      });
    }
  }

  private cancelFovTo() {
    if (this._tweenFov) {
      this._tweenFov.kill();
    }
  }

  private createFovTimeline() {
    this._tweenFov = gsap.timeline({
      onComplete: () => {
        this._tweenFov = undefined;
      },
    });
  }

  private addFovTo(
    duration: number,
    delay: number,
    targetFov: number,
    ease?: string
  ) {
    if (IS_DEBUG) console.log("TCamera: Fov変更命令", { duration, targetFov });

    if (this._tweenFov) {
      this._tweenFov.to(this._camera, {
        duration: duration,
        delay: delay,
        fov: targetFov,
        ease: ease,
        onUpdate: (f) => {
          this._isRequestedUpdateProjectionMatrix = true;
        },
      });
    }
  }

  private moveTo(
    duration: number,
    delay: number,
    targetPos: THREE.Vector3,
    targetLook: THREE.Vector3,
    easePos?: string,
    easeLook?: string,
    isEnableControls: boolean = false,
    targetFov?: number
  ) {
    this._tControls.disable();
    this._tControls.resetTarget(targetLook);

    const targetCamera = new THREE.Camera();
    targetCamera.position.copy(targetPos);
    targetCamera.lookAt(targetLook);

    this.addPositionTo(
      duration,
      delay,
      targetCamera.position,
      easePos,
      isEnableControls
    );

    this.addRotateToEuler(duration, delay, targetCamera.rotation, easeLook);

    if (targetFov) {
      this.addFovTo(duration, delay, targetFov, easePos);
    }
  }

  private orderMoves(orders: CameraOrderTypes[]) {
    if (IS_DEBUG) console.log("TCamera: 移動命令", orders);

    orders.forEach((order) => {
      this.moveTo(
        order.dur,
        order.delay,
        order.pos,
        order.look,
        order.easePos,
        order.easeLook,
        order.isEnableControls,
        order.fov
      );
    });
  }

  /** -----------------------------------
   * ChangeModes
   * ----------------------------------- */
  private changeHomeMode() {
    this.moveTo(
      TRANSITION_DURATION,
      TRANSITION_DELAY,
      CAMERA_HOME_POS,
      CAMERA_HOME_LOOK,
      undefined,
      "power2.out",
      true,
      CAMERA_FOV
    );
  }

  private changeFarMode() {
    this.moveTo(
      TRANSITION_DURATION,
      TRANSITION_DELAY,
      CAMERA_FAR_POS,
      CAMERA_FAR_LOOK,
      undefined,
      undefined,
      false,
      CAMERA_FOV
    );
  }

  private changeFrontMode() {
    this.moveTo(
      TRANSITION_HALF + 0.2,
      TRANSITION_DELAY,
      new THREE.Vector3(0, 0, 5),
      CAMERA_FRONT_LOOK,
      "power1.out",
      "power1.out",
      false,
      CAMERA_FOV
    );

    this.moveTo(
      TRANSITION_HALF - 0.2,
      TRANSITION_DELAY,
      CAMERA_FRONT_POS,
      CAMERA_FRONT_LOOK,
      "power1.out",
      "power1.out",
      false,
      CAMERA_FOV_FLAT
    );
  }

  private changeSphereMode() {
    this.moveTo(
      TRANSITION_DURATION,
      TRANSITION_DELAY,
      CAMERA_SPHERE_POS,
      CAMERA_SPHERE_LOOK,
      undefined,
      "power1.out",
      false,
      CAMERA_FOV
    );
  }

  resize() {
    this._camera.aspect = window.innerWidth / window.innerHeight;
    this._camera.updateProjectionMatrix();
  }

  update(deltaTime: number): void {
    if (this._isRequestedUpdateProjectionMatrix) {
      this._isRequestedUpdateProjectionMatrix = false;
      this._camera.updateProjectionMatrix();
    }

    this._tControls.update();
  }

  changeMode(mode: ModeTypes) {
    this.cancelPositionTo();
    this.cancelRotateTo();
    this.cancelFovTo();

    this.createPosTimeline();
    this.createRotateTimeline();
    this.createFovTimeline();

    const isFinishedIntro = store.getState().loading.isFinishedIntro;

    if (!isFinishedIntro) {
      if (mode === MODE.HOME) {
        this.orderMoves(CAMERA_INTRO_ORDERS);
        return;
      } else {
        this.orderMoves(CAMERA_INTRO_SHORT_ORDERS);
      }
    }

    switch (mode) {
      case MODE.HOME:
        this.changeHomeMode();
        break;
      case MODE.ABOUT:
        this.moveTo(
          TRANSITION_DURATION,
          TRANSITION_DELAY,
          CAMERA_SINGLE_POS,
          CAMERA_SINGLE_LOOK,
          undefined,
          undefined,
          false,
          CAMERA_FOV_WIDE
        );
        break;
      case MODE.FRONT:
        this.changeFrontMode();
        break;
      case MODE.SERVICE:
        this.moveTo(
          TRANSITION_DURATION,
          TRANSITION_DELAY,
          CAMERA_SIDE_POS,
          CAMERA_SIDE_LOOK,
          undefined,
          "power2.out",
          false,
          CAMERA_FOV_FLAT
        );
        break;
      case MODE.FAR:
        this.changeFarMode();
        break;
      case MODE.SPHERE:
        this.changeSphereMode();
        break;
    }
  }

  get camera(): THREE.PerspectiveCamera {
    return this._camera;
  }
}
