import * as THREE from "three";
import {
  CameraOrderTypes,
  CardsOrderTypes,
  CubeOrderTypes,
  LightOrderTypes,
} from "../interfaces";

const V3 = THREE.Vector3;
const TE = THREE.Euler;

export const TRACKING_ID = "G-E4Z0LXJN11";

export const IS_DEBUG = false;
export const IS_NOT_DEBUG = !IS_DEBUG;

export const IS_DONT_UPDATE = false;

const IS_FAST_DUR = true && IS_DEBUG;

export const MAX_FPS = IS_DEBUG ? 20 : 60;

/* setting times */
const tRate = IS_FAST_DUR ? 0.2 : 1;

export const TRANSITION_DURATION = 1.5;
export const TRANSITION_HALF = TRANSITION_DURATION * 0.5;
export const TRANSITION_DELAY = 0;

export const INTRO_DURATION_TOTAL = 7 * tRate;
export const INTRO_SHORT_DURATION_TOTAL = 3.5 * tRate;

/* -----------------------------------
 * Logo
 * ----------------------------------- */

export const LOGO_SIZE = 1.4;
export const LOGO_LOADING_POS = new V3(0, 42, 0);
export const LOGO_LINE_COLOR = 0x000000;

export const LOGO_EASE = "power2.inOut";

export const LOGO_WHITE_FADE_DUR = 0.5 * tRate;
export const LOGO_BLACK_FADE_DUR = 0.5 * tRate;

export const LOGO_WHITE_FADE_DELAY = 2.0 * tRate;
export const LOGO_BLACK_FADE_DELAY = LOGO_WHITE_FADE_DELAY + 0.4 * tRate;

export const LOGO_WHITE_FADE_SHORT_DELAY = 0.4 * tRate;
export const LOGO_BLACK_FADE_SHORT_DELAY =
  LOGO_WHITE_FADE_SHORT_DELAY + 0.4 * tRate;

const INTRO_LOGO_FADED_OFFSET = 0.2 * tRate;
export const INTRO_DUR_LOGO_FADED =
  LOGO_BLACK_FADE_DELAY + LOGO_BLACK_FADE_DUR - INTRO_LOGO_FADED_OFFSET;
export const INTRO_SHORT_DUR_LOGO_FADED =
  LOGO_BLACK_FADE_SHORT_DELAY + LOGO_BLACK_FADE_DUR - INTRO_LOGO_FADED_OFFSET;

/* -----------------------------------
 * Camera
 * ----------------------------------- */

export const CAMERA_FOV = 65;
export const CAMERA_FOV_FLAT = 35;
export const CAMERA_FOV_WIDE = 90;
export const CAMERA_NEAR = 0.5;
export const CAMERA_FAR = 100;

export const CAMERA_LOADING_POS = new V3(0, 50, 0.01);
export const CAMERA_LOADING_LOOK = new V3(0, 0, -0.01);

export const CAMERA_HOME_POS = new V3(0, 1.5, 11);
export const CAMERA_HOME_LOOK = new V3(0, 0, 0);

export const CAMERA_FAR_POS = new V3(0, 0, 40);
export const CAMERA_FAR_LOOK = new V3(0, 0, 0);

export const CAMERA_FRONT_POS = new V3(0, 0, 4.5);
export const CAMERA_FRONT_LOOK = new V3(0, 0, 0);

export const CAMERA_SIDE_POS = new V3(-3.5, 1, 9);
export const CAMERA_SIDE_LOOK = new V3(-2.5, 1, 0);

export const CAMERA_SINGLE_POS = new V3(0, 1.5, 4);
export const CAMERA_SINGLE_LOOK = new V3(0, 2, 0);

export const CAMERA_SPHERE_POS = new V3(10, 4, 6);
export const CAMERA_SPHERE_LOOK = new V3(0, 0, 4);

export const CAMERA_INTRO_ORDERS: CameraOrderTypes[] = [
  {
    dur: 1.5 * tRate,
    delay: INTRO_DUR_LOGO_FADED,
    pos: new V3(0, 25, -0.01),
    look: new V3(0, 5, 0.01),
    easePos: "power1.in",
    easeLook: "power1.in",
  },
  {
    dur: 1.0 * tRate,
    delay: 0,
    pos: new V3(-10, 1, 10),
    look: new V3(1, 7, 0),
    easePos: "power2.out",
    easeLook: "power2.out",
  },
  {
    dur: TRANSITION_DURATION,
    delay: 0,
    pos: CAMERA_HOME_POS,
    look: CAMERA_HOME_LOOK,
    easePos: "power1.out",
    easeLook: "power1.out",
    isEnableControls: true,
  },
];

export const CAMERA_INTRO_SHORT_ORDERS: CameraOrderTypes[] = [
  {
    dur: 1.0 * tRate,
    delay: INTRO_SHORT_DUR_LOGO_FADED,
    pos: new V3(0, 20, 0),
    look: new V3(0, 5, -0.01),
    easePos: "power1.in",
    easeLook: "power1.in",
    isEnableControls: false,
    fov: CAMERA_FOV,
  },
];

/* -----------------------------------
 * Cards and Card
 * ----------------------------------- */

export const CARD_SIZE = 1;
export const CARD_SIZE_HALF = CARD_SIZE * 0.5;
export const NUM_OF_CARDS = 48 + 1;

export const CARDS_ROTATING_Y_SPEED = -0.0004;

export const CARD_APPEAR_DURATION = 1.5 * tRate;
export const CARD_APPEAR_DELAY_RANGE = 1.5 * tRate;
export const CARD_APPEAR_DELAY_OFFSET = 0.1 * tRate;

export const CARD_APPEAR_SHORT_DURATION = 0.5 * tRate;
export const CARD_APPEAR_SHORT_DELAY_RANGE = 0.0 * tRate;
export const CARD_APPEAR_SHORT_DELAY_OFFSET = 0.1 * tRate;

export const CARDS_FAR_POS = new V3(0, 15, 0);
export const CARDS_FAR_ROT = new TE(0, 0, 0);

export const CARDS_LOADING_POS = new V3(0, 41.5, 0);
export const CARDS_LOADING_ROT = new TE(0, Math.PI * 2, 0);

export const CARDS_HIDE_POS = new V3(0, 20, 0);
export const CARDS_HIDE_ROT = new TE(0, 0, 0);

export const CARDS_SPHERE_POS = new V3(0, 1, 0.5);
export const CARDS_SPHERE_ROT = new TE(0, 0, -Math.PI * 0.15);

export const CARDS_HOME_POS = new V3(0, 0, 0);
export const CARDS_HOME_ROT = new TE(0, 0, -0.13);

export const CARDS_INTRO_ORDERS: CardsOrderTypes[] = [
  {
    dur: 1.5 * tRate,
    delay: INTRO_DUR_LOGO_FADED,
    pos: new V3(0, 12, 0),
    rot: new TE(0, Math.PI * -0.5, 0),
    ease: "power2.out",
  },
  {
    dur: 1.0 * tRate,
    delay: 0.1,
    pos: new V3(0, 8, 0),
    rot: new TE(0, Math.PI * 0.6, 0.04),
    ease: "power2.in",
  },
];

export const CARDS_INTRO_SHORT_ORDERS: CardsOrderTypes[] = [
  {
    dur: 1 * tRate,
    delay: INTRO_SHORT_DUR_LOGO_FADED,
    pos: new V3(0, 30, 0),
    rot: new TE(0, Math.PI * 3, 0),
    ease: "power1.in",
  },
];

/* -----------------------------------
 * Cards Formations
 * ----------------------------------- */

export const CARDS_FORM_INTRO_DUR = 1.4;
export const CARDS_FORM_INTRO_SHORT_DUR = 1;

export const CARD_RANDOM_FORM_DISTANCE = 4;
export const CARD_RANDOM_FORM_MIN_DISTANCE = 2;
export const CARD_RANDOM_FORM_CENTER_POS = new V3(0, 0, 0);

export const CARD_CIRCLE_FORM_RADIUS = 9.2;
export const CARD_WIDE_CIRCLE_FORM_RADIUS = 15;

export const CARD_HIDE_SPACE_RATE = 2;

export const CARD_SPHERE_FORM_RADIUS_RATE = 0.12;

/* -----------------------------------
 * Cube
 * ----------------------------------- */

export const CUBE_METALNESS = 0.45;
export const CUBE_ROUGHNESS = 0.55;
export const CUBE_BUMP_SCALE = 0.025;

export const CUBE_LOADING_POS = new V3(0, 10, 0);
export const CUBE_LOADING_ROT = new TE(0, Math.PI * 2, 0);

export const CUBE_ROTATE_SPEED = -0.002;

export const CUBE_HOME_POS = new V3(0, 0, 0);
export const CUBE_HOME_ROT = new TE(0.2 * Math.PI, 0.125 * Math.PI, 0);

export const CUBE_FRONT_ROT = new TE(0, 0, 0);

export const CUBE_SINGLE_POS = new V3(0, 0, 0);
export const CUBE_SINGLE_ROT = new TE(0.2 * Math.PI, 0, 0);

export const CUBE_SIDE_POS = new V3(0, 0, 0);
export const CUBE_SIDE_ROT = new TE(0.125 * Math.PI, 0.125 * Math.PI, 0);

export const CUBE_INTRO_ORDERS: CubeOrderTypes[] = [
  {
    dur: 2.0 * tRate,
    delay: INTRO_DUR_LOGO_FADED,
    pos: new V3(0, 8.5, 0),
    rot: new TE(0, Math.PI, 0),
    ease: "power2.out",
  },
];

export const CUBE_INTRO_SHORT_ORDERS: CubeOrderTypes[] = [
  {
    dur: 1 * tRate,
    delay: INTRO_SHORT_DUR_LOGO_FADED,
    pos: new V3(0, 1, 0),
    rot: new TE(0, Math.PI, 0),
    ease: "power2.out",
  },
];

/* -----------------------------------
 * Lights
 * ----------------------------------- */

export const LIGHT_AMBIENT_COLOR = 0xffffff;
export const LIGHT_AMBIENT_INTENSITY = 1;

export const LIGHT_DIRECT_COLOR = 0xffffff;
export const LIGHT_DIRECT_ANGLE = Math.PI / 6;
export const LIGHT_DIRECT_INTENSITY = 2;

/* mode */
export const LIGHT_DIRECT_1_FRONT_POS = new V3(0, 10, 7.5);
export const LIGHT_DIRECT_2_FRONT_POS = new V3(0, -10, -10);

export const LIGHT_DIRECT_1_SIDE_POS = new V3(-10, 1, 1);
export const LIGHT_DIRECT_2_SIDE_POS = new V3(0, 5, 10);

export const LIGHT_DIRECT_1_SINGLE_POS = new V3(-10, 3, 1);
export const LIGHT_DIRECT_2_SINGLE_POS = new V3(10, 4, -1);

export const LIGHT_DIRECT_DEF_TAR_POS = new V3(0, 0, 0);

/* home */
export const LIGHT_DIRECT_1_HOME_POS = new V3(-10, -20, 5);
export const LIGHT_DIRECT_2_HOME_POS = new V3(-3, 18, -8);

/* loading */
export const LIGHT_DIRECT_1_LOADING_POS = new V3(-20, 20, -20);
export const LIGHT_DIRECT_1_LOADING_TAR_POS = new V3(20, 0, -20);

export const LIGHT_DIRECT_2_LOADING_POS = new V3(20, 20, 20);
export const LIGHT_DIRECT_2_LOADING_TAR_POS = new V3(-20, 0, 20);

/* Intro */
export const LIGHT_DIRECT_1_INTRO_ORDERS: LightOrderTypes[] = [
  {
    dur: 1.5 * tRate,
    delay: INTRO_DUR_LOGO_FADED,
    pos: new V3(-8, 30, -8),
    tarPos: new V3(0, 0, 0),
    ease: "power3.in",
  },
  {
    dur: 1.2 * tRate,
    delay: 0,
    pos: new V3(-20, 0, -10),
    tarPos: LIGHT_DIRECT_DEF_TAR_POS,
    ease: "power2.out",
  },
];

export const LIGHT_DIRECT_2_INTRO_ORDERS: LightOrderTypes[] = [
  {
    dur: 1.5 * tRate,
    delay: INTRO_DUR_LOGO_FADED,
    pos: new V3(-8, 30, -8),
    tarPos: new V3(0, 0, 0),
    ease: "power3.in",
  },
  {
    dur: 1.2,
    delay: 0,
    pos: new V3(-10, 0, 20),
    tarPos: LIGHT_DIRECT_DEF_TAR_POS,
    ease: "power2.out",
  },
];

/* Intro Short */
export const LIGHT_DIRECT_1_INTRO_SHORT_ORDERS: LightOrderTypes[] = [
  {
    dur: 1 * tRate,
    delay: INTRO_SHORT_DUR_LOGO_FADED,
    pos: new V3(20, 30, 0),
    tarPos: LIGHT_DIRECT_DEF_TAR_POS,
  },
];

export const LIGHT_DIRECT_2_INTRO_SHORT_ORDERS: LightOrderTypes[] = [
  {
    dur: 1 * tRate,
    delay: INTRO_SHORT_DUR_LOGO_FADED,
    pos: new V3(0, 30, 20),
    tarPos: LIGHT_DIRECT_DEF_TAR_POS,
  },
];
