export const PI_HALF = Math.PI * 0.5;
export const PI2 = Math.PI * 2;

export const isSpTab =
  navigator.userAgent.indexOf("iPhone") > 0 ||
  (navigator.userAgent.indexOf("Android") > 0 &&
    navigator.userAgent.indexOf("Mobile") > 0) ||
  navigator.userAgent.indexOf("iPad") > 0 ||
  navigator.userAgent.indexOf("Android") > 0;

let currentInnerWidth = 0;

export const judgeResized = (): boolean => {
  if (isSpTab) {
    if (currentInnerWidth === window.innerWidth) {
      currentInnerWidth = window.innerWidth;

      return false;
    }
  }

  return true;
};

export const shuffleArray = ([...array]) => {
  for (let i = array.length - 1; i >= 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
};

export const totalNum = (array: number[]): number => {
  return array.reduce((acc, cur) => acc + cur, 0);
};
