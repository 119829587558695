import gsap from "gsap";
import * as THREE from "three";
import { CubeOrderTypes } from "../interfaces";
import {
  CUBE_BUMP_SCALE,
  CUBE_FRONT_ROT,
  CUBE_HOME_POS,
  CUBE_HOME_ROT,
  CUBE_INTRO_ORDERS,
  CUBE_INTRO_SHORT_ORDERS,
  CUBE_LOADING_POS,
  CUBE_LOADING_ROT,
  CUBE_METALNESS,
  CUBE_ROTATE_SPEED,
  CUBE_ROUGHNESS,
  CUBE_SIDE_POS,
  CUBE_SIDE_ROT,
  CUBE_SINGLE_POS,
  CUBE_SINGLE_ROT,
  IS_DEBUG,
  TRANSITION_DELAY,
  TRANSITION_DURATION,
} from "../lib/configs";
import { MODE, ModeTypes } from "../lib/const";
import { PI2, PI_HALF } from "../lib/utils";
import { store } from "../store/store";
import { TDebug } from "./TDebug";
import { TLoader } from "./TLoader";
import { createBoxWithRoundedEdges } from "./utils/createBoxWithRoundedEdges";

const CUBE_SIZE = 4;
const CUBE_RADIUS = 0.08;
const CUBE_SMOOTHNESS = 8;
const CUBE_BUMP_TEXTURE_PATH = "./images/textures/cube_bump_texture.jpg";

const CUBE_HIDDEN_SIZE = CUBE_SIZE * 0.4;
const CUBE_HIDDEN_TEXTURE_PATH = "./images/textures/cube_hidden_texture.png";

const CUBE_SHADOW_SIZE = 6;
const CUBE_SHADOW_TEXTURE_PATH = "./images/textures/cube_shadow_texture.png";
const CUBE_SHADOW_OPACITY = 0.5;
const CUBE_SHADOW_HOME_POS = new THREE.Vector3(0, -4, 0);

const CUBE_BLACK_COLOR = 0x111111;
const CUBE_RED_COLOR = 0x991111;

export class TCube {
  private _cube: THREE.Group;
  private _redCube: THREE.Mesh;
  private _blackCube: THREE.Mesh;
  private _cubeShadow: THREE.Mesh;
  private _hiddenMesh: THREE.Mesh;
  private _isRotating = false;
  private _tweenPos?: gsap.core.Timeline;
  private _tweenRotate?: gsap.core.Timeline;

  constructor() {
    const blackGeometry = createBoxWithRoundedEdges(
      CUBE_SIZE,
      CUBE_SIZE * 0.5 + CUBE_RADIUS * 2,
      CUBE_SIZE,
      CUBE_RADIUS,
      CUBE_SMOOTHNESS
    );
    const redGeometry = createBoxWithRoundedEdges(
      CUBE_SIZE,
      CUBE_SIZE * 0.5 + CUBE_RADIUS * 2,
      CUBE_SIZE,
      CUBE_RADIUS,
      CUBE_SMOOTHNESS
    );

    const loader = TLoader.loader;
    const bumpTexture = loader.load(CUBE_BUMP_TEXTURE_PATH);
    bumpTexture.repeat.set(0.5, 0.5);
    bumpTexture.wrapS = THREE.RepeatWrapping;
    bumpTexture.wrapT = THREE.RepeatWrapping;

    const blackMaterial = new THREE.MeshStandardMaterial({
      color: CUBE_BLACK_COLOR,
      metalness: CUBE_METALNESS,
      roughness: CUBE_ROUGHNESS,
      bumpMap: bumpTexture,
      bumpScale: CUBE_BUMP_SCALE,
    });
    const redMaterial = new THREE.MeshStandardMaterial({
      color: CUBE_RED_COLOR,
      metalness: CUBE_METALNESS,
      roughness: CUBE_ROUGHNESS,
      bumpMap: bumpTexture,
      bumpScale: CUBE_BUMP_SCALE,
    });

    this._blackCube = new THREE.Mesh(blackGeometry, blackMaterial);
    this._blackCube.position.y += CUBE_SIZE * 0.25;
    this._redCube = new THREE.Mesh(redGeometry, redMaterial);
    this._redCube.position.y -= CUBE_SIZE * 0.25;

    const hiddenGeometry = new THREE.PlaneGeometry(
      CUBE_HIDDEN_SIZE,
      CUBE_HIDDEN_SIZE
    );

    const hiddenTexture = loader.load(CUBE_HIDDEN_TEXTURE_PATH);
    const hiddenMaterial = new THREE.MeshBasicMaterial({
      map: hiddenTexture,
      transparent: true,
      alphaTest: 0.5,
    });

    this._hiddenMesh = new THREE.Mesh(hiddenGeometry, hiddenMaterial);
    this._hiddenMesh.position.y = CUBE_RADIUS + 0.01;
    this._hiddenMesh.rotation.x = -PI_HALF;

    this._cube = new THREE.Group();
    this._cube.add(this._hiddenMesh);
    this._cube.add(this._blackCube);
    this._cube.add(this._redCube);

    this._cube.position.copy(CUBE_LOADING_POS);
    this._cube.rotation.copy(CUBE_LOADING_ROT);

    const shadowGeometry = new THREE.PlaneGeometry(
      CUBE_SHADOW_SIZE,
      CUBE_SHADOW_SIZE
    );

    const shadowTexture = loader.load(CUBE_SHADOW_TEXTURE_PATH);
    const shadowMaterial = new THREE.MeshBasicMaterial({
      color: 0xffffff,
      opacity: CUBE_SHADOW_OPACITY,
      transparent: true,
      map: shadowTexture,
    });

    this._cubeShadow = new THREE.Mesh(shadowGeometry, shadowMaterial);
    this._cubeShadow.position.copy(CUBE_SHADOW_HOME_POS);
    this._cubeShadow.rotation.x = -0.5 * Math.PI;

    if (IS_DEBUG) {
      this.setupDebugGui();
    }
  }

  private setupDebugGui() {
    const gui = TDebug.gui;
    const folder = gui.addFolder("TCube");

    const cubeFolder = folder.addFolder("Cube");
    cubeFolder.add(this._cube, "visible");
    cubeFolder.add(this._cube.position, "x", -10, 10);
    cubeFolder.add(this._cube.position, "y", -10, 10);
    cubeFolder.add(this._cube.position, "z", -10, 10);
    cubeFolder.add(this._cube.scale, "x", 0, 4);
    cubeFolder.add(this._cube.scale, "y", 0, 4);
    cubeFolder.add(this._cube.scale, "z", 0, 4);

    const blackMaterial = this._blackCube
      .material as THREE.MeshStandardMaterial;
    cubeFolder.add(blackMaterial, "metalness", 0, 1);
    cubeFolder.add(blackMaterial, "roughness", 0, 1);
    cubeFolder.add(blackMaterial, "bumpScale", 0, 1);

    const shadowFolder = folder.addFolder("CubeShadow");
    shadowFolder.add(this._cubeShadow, "visible");
    shadowFolder.add(this._cubeShadow.position, "x", -10, 10);
    shadowFolder.add(this._cubeShadow.position, "y", -10, 10);
    shadowFolder.add(this._cubeShadow.position, "z", -10, 10);
    shadowFolder
      .add(this._cubeShadow.scale, "x", 0, 4)
      .step(0.01)
      .name("ScaleX");
    shadowFolder
      .add(this._cubeShadow.scale, "y", 0, 4)
      .step(0.01)
      .name("ScaleY");
    shadowFolder
      .add(this._cubeShadow.scale, "z", 0, 4)
      .step(0.01)
      .name("ScaleZ");
    shadowFolder.add(this._cubeShadow.material, "opacity", 0, 1);
  }

  private cancelRotateTo() {
    this._isRotating = false;

    if (this._tweenRotate) {
      this._tweenRotate.kill();
      this._tweenRotate = undefined;
    }
  }

  private cancelPosTo() {
    if (this._tweenPos) {
      this._tweenPos.kill();
      this._tweenPos = undefined;
    }
  }

  private createRotateTimeline() {
    this._tweenRotate = gsap.timeline({
      onComplete: () => (this._tweenRotate = undefined),
    });
  }

  private createPosTimeline() {
    this._tweenPos = gsap.timeline({
      onComplete: () => (this._tweenPos = undefined),
    });
  }

  private addRotateTo(
    duration: number,
    delay: number,
    targetRotate: THREE.Euler,
    ease?: string,
    isEnableRotating: boolean = false
  ) {
    if (this._tweenRotate) {
      this._tweenRotate.to(this._cube.rotation, {
        duration: duration,
        delay: delay,
        x: targetRotate.x,
        y: targetRotate.y,
        z: targetRotate.z,
        ease,
        onComplete: () => {
          this._isRotating = isEnableRotating;
        },
      });
    }
  }

  private addPosTo(
    duration: number,
    delay: number,
    targetPos: THREE.Vector3,
    ease?: string
  ) {
    if (this._tweenPos) {
      this._tweenPos.to(this._cube.position, {
        duration: duration,
        delay: delay,
        x: targetPos.x,
        y: targetPos.y,
        z: targetPos.z,
        ease,
      });
    }
  }

  private addMoveTo(
    duration: number,
    delay: number,
    targetPos: THREE.Vector3,
    targetRot: THREE.Euler,
    easePos?: string,
    easeRot?: string,
    isEnableRotating: boolean = false
  ) {
    this.addPosTo(duration, delay, targetPos, easePos);
    this.addRotateTo(duration, delay, targetRot, easeRot, isEnableRotating);
  }

  private orderMoves(orders: CubeOrderTypes[]) {
    orders.forEach((order) => {
      this.addMoveTo(
        order.dur,
        order.delay,
        order.pos,
        order.rot,
        order.ease,
        order.ease,
        order.isEnableRotating
      );
    });
  }

  private changeHomeMode() {
    this.addMoveTo(
      TRANSITION_DURATION,
      TRANSITION_DELAY,
      CUBE_HOME_POS,
      CUBE_HOME_ROT,
      undefined,
      undefined,
      true
    );
  }

  private changeFrontMode() {
    this.addMoveTo(
      TRANSITION_DURATION,
      TRANSITION_DELAY,
      CUBE_HOME_POS,
      CUBE_FRONT_ROT,
      undefined,
      undefined,
      false
    );
  }

  update(deltaTime: number) {
    if (this._isRotating) {
      this._cube.rotation.y += CUBE_ROTATE_SPEED * deltaTime;
      if (this._cube.rotation.y < 0) {
        this._cube.rotation.y += PI2;
      } else if (this._cube.rotation.y > PI2) {
        this._cube.rotation.y -= PI2;
      }

      this._cubeShadow.rotation.z += 0.02 * deltaTime;
      if (this._cubeShadow.rotation.z > PI2) {
        this._cubeShadow.rotation.z -= PI2;
      }
    }
  }

  changeMode(mode: ModeTypes) {
    if (IS_DEBUG) console.log("TCube: changeMode", mode);

    this.cancelRotateTo();
    this.cancelPosTo();

    this.createRotateTimeline();
    this.createPosTimeline();

    const { isFinishedIntro } = store.getState().loading;

    if (!isFinishedIntro) {
      if (mode === MODE.HOME) {
        this.orderMoves(CUBE_INTRO_ORDERS);
      } else {
        this.orderMoves(CUBE_INTRO_SHORT_ORDERS);
      }
    }

    switch (mode) {
      case MODE.ABOUT:
        this.addMoveTo(
          TRANSITION_DURATION,
          TRANSITION_DELAY,
          CUBE_SINGLE_POS,
          CUBE_SINGLE_ROT,
          undefined,
          undefined,
          true
        );
        break;
      case MODE.SERVICE:
        this.addMoveTo(
          TRANSITION_DURATION,
          TRANSITION_DELAY,
          CUBE_SIDE_POS,
          CUBE_SIDE_ROT,
          undefined,
          undefined,
          true
        );
        break;
      case MODE.FRONT:
        this.changeFrontMode();
        break;
      case MODE.HOME:
      case MODE.SPHERE:
      default:
        this.changeHomeMode();
        break;
    }
  }

  attachAll(scene: THREE.Scene) {
    scene.add(this._cube);
    scene.add(this._cubeShadow);
  }
}
