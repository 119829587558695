import { useEffect, useState } from "react";
import { IS_DEBUG } from "../lib/configs";

type UserNoteApiTypes = {
  id: number;
  date: string;
  title: {
    rendered: string;
  };
  _embedded: {
    author: [
      {
        name: string;
      }
    ];
  };
};

const initTexts = ["データを取得中..."];

export const useUserVoices = () => {
  const [userVoices, setUserVoices] = useState<string[]>(initTexts);

  useEffect(() => {
    const fetchUserVoices = async () => {
      const params = "_embed=author&context=embed&per_page=10";
      const response = await fetch(
        `https://member.ard-online.jp/wp-json/wp/v2/usernote?${params}`,
        { method: "GET" }
      );

      if (IS_DEBUG) console.log(`useUserVoice: UserVoiceをfetch`, response);

      if (response.ok) {
        const json = await response.json();

        const userVoices = json.map((voice: UserNoteApiTypes) => {
          const date = voice.date.split("T")[0].replace(/-/g, ".").slice(2);
          const name = voice._embedded.author[0].name;
          const title = voice.title.rendered;

          return `${date} ${name} ${title}`;
        });

        setUserVoices(userVoices);
      } else {
        setUserVoices([
          "データ取得に失敗しました。時間を置いて再度お試しください。",
        ]);
      }
    };

    fetchUserVoices();
  }, []);

  return userVoices;
};
