import * as THREE from "three";
import { IS_DEBUG, IS_DONT_UPDATE, MAX_FPS } from "../lib/configs";
import { ModeTypes } from "../lib/const";
import { Ticker } from "../lib/Ticker/Ticker";
import { judgeResized } from "../lib/utils";
import { TCamera } from "./TCamera";
import { TCards } from "./TCards";
import { TCube } from "./TCube";
import { TDebug } from "./TDebug";
import { TLights } from "./TLights";
import { TLogo } from "./TLogo";
import { TPointer } from "./TPointer";

export class TScene {
  private _scene: THREE.Scene;
  private _renderer: THREE.WebGLRenderer;
  private _tCamera: TCamera;
  private _tLights: TLights;
  private _tCards: TCards;
  private _tCube: TCube;
  private _tLogo: TLogo;
  private _tPointer: TPointer;

  constructor() {
    if (IS_DEBUG) console.log("TScene: 初期化");

    this._scene = new THREE.Scene();

    this._renderer = new THREE.WebGLRenderer({ alpha: true, antialias: true });
    this._renderer.setPixelRatio(window.devicePixelRatio);
    this._renderer.setSize(window.innerWidth, window.innerHeight);
    this._renderer.localClippingEnabled = true;

    this._tCamera = new TCamera(this._renderer.domElement);

    this._tLogo = new TLogo();
    this._tLogo.attachAll(this._scene);

    this._tLights = new TLights();
    this._tLights.attachAll(this._scene);

    this._tCube = new TCube();
    this._tCube.attachAll(this._scene);

    this._tCards = new TCards();
    this._scene.add(this._tCards.cardsGroup);

    this._tPointer = new TPointer(
      this._renderer.domElement,
      this._tCamera.camera,
      this._tCards
    );

    if (IS_DEBUG) {
      TDebug.initHelpers(this._scene);
      TDebug.initStats();
    }

    window.addEventListener("resize", this.resize.bind(this));

    Ticker.shared.add(this.update, this);
    Ticker.shared.maxFPS = MAX_FPS;
    Ticker.shared.start();
  }

  private resize() {
    if (judgeResized()) {
      if (IS_DEBUG) console.log("TSceneをリサイズ");

      this._renderer.setPixelRatio(window.devicePixelRatio);
      this._renderer.setSize(window.innerWidth, window.innerHeight);
      this._tCamera.resize();
    }
  }

  private update(deltaTime: number) {
    if (IS_DEBUG) TDebug.beginStats();

    if (IS_DEBUG && IS_DONT_UPDATE) return;

    this._tLogo.update(deltaTime);

    this._tCube.update(deltaTime);
    this._tCards.update(deltaTime);
    this._tLights.update(deltaTime);
    this._tCamera.update(deltaTime);

    this._renderer.render(this._scene, this._tCamera.camera);

    if (IS_DEBUG) TDebug.endStats();
  }

  startIntro() {
    if (IS_DEBUG) console.log("TScene: イントロを開始");
  }

  finishIntro() {
    if (IS_DEBUG) console.log("TScene: イントロを終了");

    this._tPointer.finishIntro();
  }

  changeMode(mode: ModeTypes) {
    if (IS_DEBUG) console.log(`TScene: モードを${mode}に変更`);

    this._tLogo.changeMode(mode);
    this._tCube.changeMode(mode);
    this._tCards.changeMode(mode);
    this._tCamera.changeMode(mode);
    this._tLights.changeMode(mode);
    this._tPointer.changeMode(mode);
  }

  get canvas(): HTMLCanvasElement {
    return this._renderer.domElement;
  }
}
