import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { IS_DEBUG } from "../lib/configs";

export class TControls {
  private _controls: OrbitControls;

  constructor(camera: THREE.Camera, canvas: HTMLElement) {
    this._controls = new OrbitControls(camera, canvas);

    this._controls.enabled = false;
    this._controls.maxPolarAngle = Math.PI * 0.55;
    this._controls.minDistance = 2;
    this._controls.maxDistance = 30;
    this._controls.rotateSpeed = 0.8;
    this._controls.zoomSpeed = 0.45;
    this._controls.enablePan = false;
    this._controls.enableDamping = true;
    this._controls.dampingFactor = 0.1;
  }

  update() {
    if (this._controls.enabled) {
      this._controls.update();
    }
  }

  resetTarget(target: THREE.Vector3) {
    if (IS_DEBUG) console.log("TControls: targetをreset", target);

    this._controls.target.copy(target);
  }

  enable() {
    if (IS_DEBUG) console.log("TControls: 有効化");

    this._controls.enabled = true;
  }

  disable() {
    if (IS_DEBUG) console.log("TControls: 無効化");

    this._controls.enabled = false;
  }

  get controls(): OrbitControls {
    return this._controls;
  }
}
