import { animated, useSprings } from "react-spring";

export const BlackBackLayer = () => {
  return <div className="fixed inset-0 bg-black/75" />;
};

export const WhiteBackLayer = () => {
  return <div className="fixed inset-0 bg-stone-50/95" />;
};

const BACK_IMAGE_LOOP_DUR = 60000;

export const BackImageLayer = () => {
  const [springs] = useSprings(4, (index) => {
    let fromX = 0;
    let fromY = 0;

    if (index === 1 || index === 3) {
      fromX = -100;
    }
    if (index === 2 || index === 3) {
      fromY = 100;
    }

    return {
      from: {
        x: `${fromX}%`,
        y: `${fromY}%`,
      },
      to: {
        x: `${fromX + 100}%`,
        y: `${fromY - 100}%`,
      },
      config: {
        duration: BACK_IMAGE_LOOP_DUR,
      },
      loop: true,
    };
  });

  return (
    <div className="fixed inset-0">
      {springs.map((styles, index) => {
        return (
          <animated.img
            key={index}
            className="absolute inset-0 block w-full h-full object-cover"
            src="./images/bg.jpg"
            alt=""
            style={styles}
          />
        );
      })}
    </div>
  );
};
