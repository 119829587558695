import { FC, ReactNode } from "react";

type SingleLayoutProps = {
  children: ReactNode;
  maxWidth?: "sm" | "md" | "lg" | "xl" | "2xl";
};

export const SingleLayout: FC<SingleLayoutProps> = ({ children, maxWidth }) => {
  let maxWidthCN = "max-w-screen-md";

  switch (maxWidth) {
    case "sm":
      maxWidthCN = "max-w-screen-sm";
      break;
    case "md":
      maxWidthCN = "max-w-screen-md";
      break;
    case "lg":
      maxWidthCN = "max-w-screen-lg";
      break;
    case "xl":
      maxWidthCN = "max-w-screen-xl";
      break;
    case "2xl":
      maxWidthCN = "max-w-screen-2xl";
      break;
  }

  return (
    <div className="fixed z-20 top-0 inset-x-0 max-h-full overflow-y-auto">
      <div className={`mx-auto my-24 sm:my-32 w-11/12 sm:w-9/12 ${maxWidthCN}`}>
        {children}
      </div>
    </div>
  );
};
