import { UserVoice } from "../components/atoms/UserVoice";

export const HomeBackLayer = () => null;

export const HomePage = () => {
  return (
    <div>
      <UserVoice />
    </div>
  );
};
