import {
  CatchText,
  Content,
  ContentTitle,
  RedTitle,
  SinceText,
} from "../components/atoms/SideLayoutObjs";
import { BackImageLayer } from "../components/layout/BackgroundLayer";
import { SideLayout } from "../components/layout/SideLayout";

export const ServiceBackLayer = () => {
  return (
    <div>
      <BackImageLayer />
    </div>
  );
};

export const ServicePage = () => {
  return (
    <div>
      <SideLayout>
        <RedTitle>Service</RedTitle>
        <CatchText>招待制ビジネスコミュニティ</CatchText>
        <ContentTitle>ALLROUNDER</ContentTitle>
        <SinceText>Since 2012</SinceText>

        <Content>
          <p>「最高の人材を作る」をテーマに大学生・社会人に向けて経営・マーケティング・PR・ファイナンスを提供している育成期間。</p>
          <p>プロジェクトの企画・運営を通じて自己実現できる人材育成を目指し、ヒト・場所・育成を軸に優秀な人材が育つ環境を目指す。</p>
          <p>東京・大阪・広島・福岡・北海道を中心に活動、2020年新型コロナウイルスを機会に、リアル中心だった活動から、
            オンラインを大幅に強化し、その結果全国から参加者が増えより拡大に成功した。さらにメタバースオフィスを立ち上げ
            今後グローバル展開を積極的に力を入れる</p>
          <p>2012年から10年、延べ5000名を超えるメンバーを指導。</p>
        </Content>
      </SideLayout>
    </div>
  );
};
