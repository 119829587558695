import { Route, Routes } from "react-router-dom";
import { animated } from "react-spring";
import { SITE_PATH } from "../../lib/const";
import { About } from "../../pages/About";
import { Company } from "../../pages/Company";
import { Contact } from "../../pages/Contact";
import { HomePage } from "../../pages/Home";
import { ServicePage } from "../../pages/Service";
import { TeamPage } from "../../pages/Team";
import { useTransitionRouter } from "./useTransitionRouter";

export const FrontRouter = () => {
  const transitions = useTransitionRouter();

  return transitions((styles, item) => (
    <animated.div style={styles} className="opacity-0">
      <Routes location={item}>
        <Route path={SITE_PATH.HOME} element={<HomePage />} />
        <Route path={SITE_PATH.SERVICE} element={<ServicePage />} />
        <Route path={SITE_PATH.ABOUT} element={<About />} />
        <Route path={SITE_PATH.TEAM} element={<TeamPage />} />
        <Route path={SITE_PATH.COMPANY} element={<Company />} />
        <Route path={SITE_PATH.CONTACT} element={<Contact />} />
      </Routes>
    </animated.div>
  ));
};
