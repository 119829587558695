import * as THREE from "three";
import { IS_DEBUG } from "../lib/configs";
import { setIsLoaded, setLoadingProgress } from "../store/loadingSlice";
import { store } from "../store/store";

const IS_LOADING_LOG = IS_DEBUG && false;

export class TLoader {
  private static _manager: THREE.LoadingManager;
  private static _loader: THREE.TextureLoader;

  static init() {
    if (IS_DEBUG) console.log(`TLoader: init.`);

    if (!TLoader._manager) {
      const manager = new THREE.LoadingManager();

      manager.onProgress = (item, loaded, total) => {
        if (IS_LOADING_LOG) {
          console.log(`${this.name}: ロード中(${loaded}/${total}): ${item}`);
        }

        const progress = loaded / total;
        store.dispatch(setLoadingProgress(progress));
      };

      manager.onLoad = () => {
        if (IS_DEBUG) console.log(`${this.name}: ロード完了`);

        store.dispatch(setIsLoaded(true));
      };

      manager.onError = (err) => {
        if (IS_DEBUG) console.error(`${this.name}: ロードエラー`, err);
      };

      TLoader._manager = manager;
    }

    if (!TLoader._loader) {
      TLoader._loader = new THREE.TextureLoader(TLoader._manager);
    }
  }

  static get loader() {
    if (!TLoader._loader) {
      console.error("TLoader is not initialized.");
    }

    return TLoader._loader;
  }
}
