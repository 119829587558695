import gsap from "gsap";
import * as THREE from "three";
import { carddataShuffledArray } from "../assets/carddata";
import { CardsOrderTypes } from "../interfaces";
import {
  CARDS_FAR_POS,
  CARDS_FAR_ROT,
  CARDS_FORM_INTRO_DUR,
  CARDS_FORM_INTRO_SHORT_DUR,
  CARDS_HIDE_POS,
  CARDS_HIDE_ROT,
  CARDS_HOME_POS,
  CARDS_HOME_ROT,
  CARDS_INTRO_ORDERS,
  CARDS_INTRO_SHORT_ORDERS,
  CARDS_LOADING_POS,
  CARDS_LOADING_ROT,
  CARDS_ROTATING_Y_SPEED,
  CARDS_SPHERE_POS,
  CARDS_SPHERE_ROT,
  CARD_APPEAR_DELAY_OFFSET,
  CARD_APPEAR_DELAY_RANGE,
  CARD_APPEAR_DURATION,
  CARD_APPEAR_SHORT_DELAY_OFFSET,
  CARD_APPEAR_SHORT_DELAY_RANGE,
  CARD_APPEAR_SHORT_DURATION,
  INTRO_DUR_LOGO_FADED,
  INTRO_SHORT_DUR_LOGO_FADED,
  IS_DEBUG,
  IS_NOT_DEBUG,
  NUM_OF_CARDS,
  TRANSITION_DELAY,
  TRANSITION_DURATION,
  TRANSITION_HALF,
} from "../lib/configs";
import { MODE, ModeTypes } from "../lib/const";
import { PI2 } from "../lib/utils";
import { store } from "../store/store";
import { TCard } from "./TCard";
import { TDebug } from "./TDebug";
import { TFormations } from "./TFormations";

/**
 * TCards
 */
export class TCards {
  private _tFormations: TFormations;
  private _cardsGroup: THREE.Group = new THREE.Group();
  private _cards: TCard[] = [];
  private _isRotating: boolean = false;
  private _tweenPos?: gsap.core.Timeline;
  private _tweenRotate?: gsap.core.Timeline;

  constructor() {
    this._tFormations = new TFormations();

    for (let i = 0; i < NUM_OF_CARDS; i++) {
      if (i < carddataShuffledArray.length) {
        const pickedCardData = carddataShuffledArray[i];

        const tCard = new TCard({
          uid: pickedCardData.id,
          philosophy: pickedCardData.philosophy,
        });

        this._cards.push(tCard);
        this._cardsGroup.add(tCard.card);
      }
    }

    this._cardsGroup.position.copy(CARDS_LOADING_POS);
    this._cardsGroup.rotation.copy(CARDS_LOADING_ROT);

    this.setFormation(this._tFormations.randomFormation);

    if (IS_DEBUG) {
      this.setupDebugGui();
    }
  }

  private setupDebugGui() {
    if (IS_NOT_DEBUG) return;

    const gui = TDebug.gui;
    const folder = gui.addFolder("TCards");

    const common = {
      duration: 1,
    };
    folder.add(common, "duration", 0, 10);

    folder.add(this._cardsGroup.rotation, "x", -Math.PI, Math.PI).name("rot x");
    folder.add(this._cardsGroup.rotation, "y", -Math.PI, Math.PI).name("rot y");
    folder.add(this._cardsGroup.rotation, "z", -Math.PI, Math.PI).name("rot z");

    const guifn = {
      formToCircleFormations: () => {
        this.addFormTo(common.duration, 0, this._tFormations.circleFormation);
      },
    };

    folder.add(guifn, "formToCircleFormations");
  }

  private cancelPosTo() {
    if (this._tweenPos) {
      this._tweenPos.kill();
      this._tweenPos = undefined;
    }
  }

  private cancelRotateTo() {
    this._isRotating = false;

    if (this._tweenRotate) {
      this._tweenRotate.kill();
      this._tweenRotate = undefined;
    }
  }

  private createPosTimeline() {
    this._tweenPos = gsap.timeline({
      onComplete: () => (this._tweenPos = undefined),
    });
  }

  private createRotateTimeline() {
    this._tweenRotate = gsap.timeline({
      onComplete: () => (this._tweenRotate = undefined),
    });
  }

  private addPosTo(
    duration: number,
    delay: number,
    targetPos: THREE.Vector3,
    ease?: string
  ) {
    if (this._tweenPos) {
      this._tweenPos.to(this._cardsGroup.position, {
        duration: duration,
        delay: delay,
        x: targetPos.x,
        y: targetPos.y,
        z: targetPos.z,
        ease,
      });
    }
  }

  private addRotateTo(
    duration: number,
    delay: number,
    targetRotate: THREE.Euler,
    ease?: string,
    isEnableRotating: boolean = false
  ) {
    if (this._tweenRotate) {
      this._tweenRotate.to(this._cardsGroup.rotation, {
        duration: duration,
        delay: delay,
        x: targetRotate.x,
        y: targetRotate.y,
        z: targetRotate.z,
        ease,
        onComplete: () => {
          this._isRotating = isEnableRotating;
        },
      });
    }
  }

  private addMoveTo(
    duration: number,
    delay: number,
    targetPos: THREE.Vector3,
    targetRot: THREE.Euler,
    easePos?: string,
    easeRot?: string,
    isEnableRotating: boolean = false
  ) {
    this.addPosTo(duration, delay, targetPos, easePos);
    this.addRotateTo(duration, delay, targetRot, easeRot, isEnableRotating);
  }

  private orderMoves(orders: CardsOrderTypes[]) {
    orders.forEach((order) => {
      this.addMoveTo(
        order.dur,
        order.delay,
        order.pos,
        order.rot,
        order.ease,
        order.ease,
        order.isEnableRotating
      );
    });
  }

  private cancelFormTo() {
    this._cards.forEach((tCard) => {
      tCard.cancelMoveTo();
    });
  }

  private createFormTimelines() {
    this._cards.forEach((tCard) => {
      tCard.createMoveTimelines();
    });
  }

  private addFormTo(
    duration: number,
    delay: number,
    formation: THREE.Object3D[]
  ) {
    if (IS_DEBUG) console.log("TCards: CardFormations変更命令", formation);

    for (let j = 0; j < formation.length; j++) {
      const target = formation[j].clone();
      const tCard = this._cards[j];

      tCard.addMoveTo(duration, delay, target.position, target.rotation);
    }
  }

  private setFormation(formation: THREE.Object3D[]) {
    this._cards.forEach((tCard, i) => {
      const target = formation[i].clone();
      tCard.setPosition(target.position);
      tCard.setRotation(target.rotation);
    });
  }

  private createAppearCardsTimelines() {
    this._cards.forEach((tCard) => {
      tCard.createAppearTimeline();
    });
  }

  private cancelAppearCardsTo() {
    this._cards.forEach((tCard) => {
      tCard.cancelAppearTo();
    });
  }

  private addAppearCardsTo(
    duration: number,
    delayOffset: number,
    delayRange: number,
    opacity: number,
    ease?: string
  ) {
    for (let i = 0; i < this._cards.length; i++) {
      const delay = delayOffset + Math.random() * delayRange;

      this._cards[i].addAppearTo(duration, delay, opacity, ease);
    }
  }

  private queIntroAnime() {
    this.addAppearCardsTo(
      CARD_APPEAR_DURATION,
      CARD_APPEAR_DELAY_OFFSET,
      CARD_APPEAR_DELAY_RANGE,
      1
    );

    this.orderMoves(CARDS_INTRO_ORDERS);

    this.addFormTo(
      CARDS_FORM_INTRO_DUR,
      INTRO_DUR_LOGO_FADED,
      this._tFormations.wideCircleFormation
    );
  }

  private queIntroShortAnime(mode: ModeTypes) {
    this.addAppearCardsTo(
      CARD_APPEAR_SHORT_DURATION,
      CARD_APPEAR_SHORT_DELAY_OFFSET,
      CARD_APPEAR_SHORT_DELAY_RANGE,
      1
    );

    if (
      mode === MODE.ABOUT ||
      mode === MODE.SERVICE ||
      mode === MODE.FRONT ||
      mode === MODE.FAR
    ) {
      this.addAppearCardsTo(INTRO_SHORT_DUR_LOGO_FADED, 0, 0, 1);
    }

    this.orderMoves(CARDS_INTRO_SHORT_ORDERS);

    this.addFormTo(
      CARDS_FORM_INTRO_SHORT_DUR,
      INTRO_SHORT_DUR_LOGO_FADED,
      this._tFormations.wideCircleFormation
    );
  }

  private changeHomeMode() {
    this.addMoveTo(
      TRANSITION_DURATION,
      TRANSITION_DELAY,
      CARDS_HOME_POS,
      CARDS_HOME_ROT,
      "power2.out",
      "power2.out",
      true
    );

    this.addAppearCardsTo(TRANSITION_DURATION, 0, 0, 1);

    this.addFormTo(
      TRANSITION_DURATION,
      TRANSITION_DELAY,
      this._tFormations.circleFormation
    );
  }

  private changeFarMode() {
    this.addMoveTo(
      TRANSITION_DURATION,
      TRANSITION_DELAY,
      CARDS_FAR_POS,
      CARDS_FAR_ROT,
      undefined,
      undefined,
      true
    );

    this.addAppearCardsTo(TRANSITION_HALF, 0, 0, 0);

    this.addFormTo(
      TRANSITION_DURATION,
      TRANSITION_DELAY,
      this._tFormations.superWideCircleFormation
    );
  }

  private changeFrontMode() {
    this.addMoveTo(
      TRANSITION_DURATION,
      TRANSITION_DELAY,
      CARDS_HIDE_POS,
      CARDS_HIDE_ROT,
      "power2.out",
      "power2.out",
      true
    );

    this.addAppearCardsTo(TRANSITION_DURATION, 0, 0, 0);

    this.addFormTo(
      TRANSITION_DURATION,
      TRANSITION_DELAY,
      this._tFormations.sphereFormation
    );
  }

  private changeSphereMode() {
    this.addMoveTo(
      TRANSITION_DURATION,
      TRANSITION_DELAY,
      CARDS_SPHERE_POS,
      CARDS_SPHERE_ROT,
      undefined,
      undefined,
      true
    );

    this.addAppearCardsTo(TRANSITION_DURATION, 0, 0, 1);

    this.addFormTo(
      TRANSITION_DURATION,
      TRANSITION_DELAY,
      this._tFormations.sphereFormation
    );
  }

  update(deltaTime: number) {
    if (this._isRotating) {
      this._cardsGroup.rotation.y += CARDS_ROTATING_Y_SPEED * deltaTime;

      if (this._cardsGroup.rotation.y < 0) {
        this._cardsGroup.rotation.y += PI2;
      } else if (this._cardsGroup.rotation.y > PI2) {
        this._cardsGroup.rotation.y -= PI2;
      }
    }
  }

  changeMode(mode: ModeTypes) {
    if (IS_DEBUG) console.log(`TCards: モードに従い移動`, mode);

    this.cancelPosTo();
    this.cancelRotateTo();

    this.createPosTimeline();
    this.createRotateTimeline();

    this.cancelFormTo();
    this.createFormTimelines();

    this.cancelAppearCardsTo();
    this.createAppearCardsTimelines();

    const { isFinishedIntro } = store.getState().loading;

    if (!isFinishedIntro) {
      if (mode === MODE.HOME) {
        this.queIntroAnime();
      } else {
        this.queIntroShortAnime(mode);
      }
    }

    switch (mode) {
      case MODE.HOME:
        this.changeHomeMode();
        break;
      case MODE.ABOUT:
      case MODE.FRONT:
      case MODE.SERVICE:
        this.changeFrontMode();
        break;
      case MODE.FAR:
        this.changeFarMode();
        break;
      case MODE.SPHERE:
        this.changeSphereMode();
        break;
    }
  }

  getTCardByObjId(objId: number): TCard | undefined {
    let tCard: TCard | undefined = undefined;

    for (let i = 0; i < this._cards.length; i++) {
      if (objId === this._cards[i].cardMesh.id) {
        tCard = this._cards[i];
        break;
      }
    }

    return tCard;
  }

  get cardsGroup() {
    return this._cardsGroup;
  }
}
