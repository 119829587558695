import { useState } from "react";
import { animated, useSpring, useTransition } from "react-spring";
import { useUserVoices } from "../../hooks/useUserVoices";
import { useAppSelector } from "../../store/storeHooks";

const DELAY_ENTER = 500;
const DELAY_MOVE = 1000;
const TEXT_SPEED = 250;

export const UserVoice = () => {
  const { isFinishedIntro } = useAppSelector((state) => state.loading);
  const [index, setIndex] = useState(0);
  const userVoices = useUserVoices();

  const fadeInStyles = useSpring({
    opacity: isFinishedIntro ? 1 : 0,
    delay: 200,
  });

  const texts = userVoices.length > 0 ? userVoices : ["データを取得中..."];

  const textTransitions = useTransition(texts[index], {
    from: { opacity: 0, x: "0%" },
    enter: (item) => async (next, cancel) => {
      await next({ opacity: 0, x: "0%", delay: DELAY_ENTER });
      await next({ opacity: 1, x: "0%" });

      let nextIndex = index + 1;
      if (nextIndex >= texts.length) {
        nextIndex = 0;
      }
      setIndex(nextIndex);
    },
    leave: (item) => async (next, cancel) => {
      await next({
        opacity: 1,
        x: "-100.1%",
        delay: DELAY_MOVE,
        config: { duration: item.length * TEXT_SPEED },
      });
    },
    exitBeforeEnter: true,
  });

  if (!isFinishedIntro) return null;

  return (
    <animated.div
      className="fixed z-30 bottom-8 md:bottom-2 left-2 right-2 md:right-auto px-5 py-3 md:w-7/12 max-w-xl rounded-full bg-stone-900/90"
      style={fadeInStyles}
    >
      <div className="flex flex-col md:flex-row md:items-center font-sans text-stone-50">
        <p className="mr-4 text-xs uppercase">UserVoice</p>
        <p className="mt-1 md:mt-0 text-sm overflow-x-hidden whitespace-nowrap">
          {textTransitions((styles, item) => {
            return (
              <animated.span style={styles} className="inline-block">
                {item}
              </animated.span>
            );
          })}
        </p>
      </div>
    </animated.div>
  );
};
