import * as THREE from "three";
import {
  CARD_CIRCLE_FORM_RADIUS,
  CARD_HIDE_SPACE_RATE,
  CARD_RANDOM_FORM_CENTER_POS,
  CARD_RANDOM_FORM_DISTANCE,
  CARD_RANDOM_FORM_MIN_DISTANCE,
  CARD_SIZE,
  CARD_SPHERE_FORM_RADIUS_RATE,
  CARD_WIDE_CIRCLE_FORM_RADIUS,
  IS_DEBUG,
  IS_NOT_DEBUG,
  NUM_OF_CARDS,
} from "../lib/configs";
import { TDebug } from "./TDebug";
import {
  generateCircleFormations,
  generateRandomFormations,
  generateSphereFormations,
  generateTableFormations,
} from "./utils/generateFormations";

export class TFormations {
  private _randomFormation: THREE.Object3D[];
  private _circleFormation: THREE.Object3D[];
  private _wideCircleFormation: THREE.Object3D[];
  private _superWideCircleFormation: THREE.Object3D[];
  private _hideFormation: THREE.Object3D[];
  private _sphereFormation: THREE.Object3D[];

  constructor() {
    this._randomFormation = generateRandomFormations(
      NUM_OF_CARDS,
      CARD_RANDOM_FORM_DISTANCE,
      CARD_RANDOM_FORM_MIN_DISTANCE,
      CARD_RANDOM_FORM_CENTER_POS,
      0
    );

    this._circleFormation = generateCircleFormations(
      NUM_OF_CARDS,
      CARD_CIRCLE_FORM_RADIUS,
      0
    );

    this._wideCircleFormation = generateCircleFormations(
      NUM_OF_CARDS,
      CARD_WIDE_CIRCLE_FORM_RADIUS,
      0
    );

    this._superWideCircleFormation = generateCircleFormations(
      NUM_OF_CARDS,
      CARD_WIDE_CIRCLE_FORM_RADIUS * 3,
      0
    );

    this._hideFormation = generateTableFormations(
      NUM_OF_CARDS,
      CARD_SIZE * CARD_HIDE_SPACE_RATE,
      CARD_SIZE * CARD_HIDE_SPACE_RATE,
      new THREE.Vector3(0, 0, 0),
      true
    );

    this._sphereFormation = generateSphereFormations(
      NUM_OF_CARDS,
      CARD_SPHERE_FORM_RADIUS_RATE
    );

    if (IS_DEBUG) {
      this.setupDebugGui();
    }
  }

  private setupDebugGui() {
    if (IS_NOT_DEBUG) return;

    const gui = TDebug.gui;
    const folder = gui.addFolder("this");

    const circleFolder = folder.addFolder("CircleFormation");
    const tempCircleFormationsConf = {
      circleRadius: CARD_CIRCLE_FORM_RADIUS,
      circleOffsetY: 0,
    };
    circleFolder.add(tempCircleFormationsConf, "circleRadius", 0, 30);
    circleFolder.add(tempCircleFormationsConf, "circleOffsetY", -20, 20);

    const guifn = {
      setCircleFormations: () => {
        this._circleFormation = generateCircleFormations(
          NUM_OF_CARDS,
          tempCircleFormationsConf.circleRadius,
          tempCircleFormationsConf.circleOffsetY
        );
      },
    };
    circleFolder.add(guifn, "setCircleFormations");
  }

  get randomFormation(): THREE.Object3D[] {
    return this._randomFormation;
  }

  get circleFormation(): THREE.Object3D[] {
    return this._circleFormation;
  }

  get wideCircleFormation(): THREE.Object3D[] {
    return this._wideCircleFormation;
  }

  get superWideCircleFormation(): THREE.Object3D[] {
    return this._superWideCircleFormation;
  }

  get hideFormation(): THREE.Object3D[] {
    return this._hideFormation;
  }

  get sphereFormation(): THREE.Object3D[] {
    return this._sphereFormation;
  }
}
