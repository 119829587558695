import { FC, ReactNode } from "react";

type SideLayoutProps = {
  children: ReactNode;
};

export const SideLayout: FC<SideLayoutProps> = ({ children }) => {
  return (
    <div className="fixed z-30 inset-0 md:left-auto md:right-1/2 flex items-center md:w-1/2 max-w-3xl">
      <div className="mx-auto mt-8 md:mt-0 md:ml-12 w-11/12 max-w-lg h-3/4 md:h-1/2 md:max-h-160 min-h-1/2 overflow-y-auto bg-white">
        <div className="px-6 md:px-14 py-8">{children}</div>
      </div>
    </div>
  );
};
