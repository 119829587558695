import { useState } from "react";
import { NavLink } from "react-router-dom";
import { animated, useSpring } from "react-spring";

type ItemProps = {
  to: string;
  label: string;
  onClick: () => void;
};

export const MenuItem: React.FC<ItemProps> = ({ to, label, onClick }) => {
  const [isHover, setIsHover] = useState(false);
  const bgStyles = useSpring({
    scaleX: isHover ? 1 : 0,
  });

  const textStyles = useSpring({
    x: isHover ? 8 : 0,
    delay: 100,
  });

  return (
    <li
      className="relative"
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
    >
      <animated.div
        className="absolute inset-0 bg-rose-700 origin-left"
        style={bgStyles}
      />
      <animated.div style={textStyles}>
        <NavLink
          to={to}
          className="block relative z-10 py-4 pl-12 text-3xl"
          onClick={onClick}
        >
          {({ isActive }) => (
            <>
              {isActive && (
                <span className="block absolute left-0 top-1/2 w-8 h-0.5 bg-rose-700" />
              )}
              {label}
            </>
          )}
        </NavLink>
      </animated.div>
    </li>
  );
};
