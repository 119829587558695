import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { IS_DEBUG } from "../lib/configs";
import { PAGE_TITLE, SITE_PATH } from "../lib/const";

export const useTitleChanger = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    switch (pathname) {
      case SITE_PATH.HOME:
        document.title = PAGE_TITLE.HOME;
        break;
      case SITE_PATH.ABOUT:
        document.title = PAGE_TITLE.ABOUT;
        break;
      case SITE_PATH.COMPANY:
        document.title = PAGE_TITLE.COMPANY;
        break;
      case SITE_PATH.CONTACT:
        document.title = PAGE_TITLE.CONTACT;
        break;
      case SITE_PATH.SERVICE:
        document.title = PAGE_TITLE.SERVICE;
        break;
      case SITE_PATH.TEAM:
        document.title = PAGE_TITLE.TEAM;
        break;
      default:
        if (IS_DEBUG) console.error(`${pathname} is not defined`);
        document.title = PAGE_TITLE.HOME;
        break;
    }
  }, [pathname]);
};
