import gsap from "gsap";
import {
  INTRO_DURATION_TOTAL,
  INTRO_SHORT_DURATION_TOTAL,
  IS_DEBUG,
  IS_NOT_DEBUG,
} from "../lib/configs";
import { MODE, ModeTypes } from "../lib/const";
import { setIsFinishedIntro } from "../store/loadingSlice";
import { store } from "../store/store";
import { TDebug } from "./TDebug";
import { TLoader } from "./TLoader";
import { TScene } from "./TScene";

export class ThreeApp {
  private _tScene: TScene;
  private _introEndTween?: gsap.core.Tween;

  constructor() {
    TLoader.init();

    this._tScene = new TScene();

    if (IS_DEBUG) {
      this.setupDebugGui();
    }
  }

  private setupDebugGui() {
    if (IS_NOT_DEBUG) return;

    const gui = TDebug.gui;
    const folder = gui.addFolder("ThreeApp");

    const params = {
      targetMode: MODE.LOADING,
    };

    const actions = {
      changeMode: () => {
        this.changeMode(params.targetMode);
      },
    };

    folder.add(params, "targetMode", Object.values(MODE));
    folder.add(actions, "changeMode").name("changeMode");
  }

  private cancelIntroEndTimer() {
    if (this._introEndTween) {
      this._introEndTween.kill();
      this._introEndTween = undefined;
    }
  }

  private startIntroEndTimer() {
    const isShortIntro = store.getState().loading.isShortIntro;

    const duration = isShortIntro
      ? INTRO_SHORT_DURATION_TOTAL
      : INTRO_DURATION_TOTAL;

    this._introEndTween = gsap.to(
      {},
      {
        duration: duration,
        onComplete: () => {
          if (IS_DEBUG) console.log(`ThreeApp: intro終了`);

          store.dispatch(setIsFinishedIntro(true));

          this.finishIntro();
        },
      }
    );
  }

  private startIntro() {
    this.cancelIntroEndTimer();
    this.startIntroEndTimer();

    this._tScene.startIntro();
  }

  private finishIntro() {
    this._tScene.finishIntro();
  }

  changeMode(mode: ModeTypes) {
    if (IS_DEBUG) {
      console.log(`${this.constructor.name} :ThreeAppモードを変更`, mode);
    }

    const isFinisedIntro = store.getState().loading.isFinishedIntro;
    if (!isFinisedIntro) {
      this.startIntro();
    }

    this._tScene.changeMode(mode);
  }

  get canvas(): HTMLCanvasElement {
    return this._tScene.canvas;
  }
}
