import { Route, Routes } from "react-router-dom";
import { animated } from "react-spring";
import { SITE_PATH } from "../../lib/const";
import { AboutBackLayer } from "../../pages/About";
import { CompanyBackLayer } from "../../pages/Company";
import { ContactBackLayer } from "../../pages/Contact";
import { HomeBackLayer } from "../../pages/Home";
import { ServiceBackLayer } from "../../pages/Service";
import { TeamBackLayer } from "../../pages/Team";
import { useTransitionRouter } from "./useTransitionRouter";

export const BackRouter = () => {
  const transitions = useTransitionRouter();

  return transitions((styles, item) => (
    <animated.div style={styles}>
      <Routes location={item}>
        <Route path={SITE_PATH.HOME} element={<HomeBackLayer />} />
        <Route path={SITE_PATH.SERVICE} element={<ServiceBackLayer />} />
        <Route path={SITE_PATH.ABOUT} element={<AboutBackLayer />} />
        <Route path={SITE_PATH.TEAM} element={<TeamBackLayer />} />
        <Route path={SITE_PATH.CONTACT} element={<ContactBackLayer />} />
        <Route path={SITE_PATH.COMPANY} element={<CompanyBackLayer />} />
      </Routes>
    </animated.div>
  ));
};
