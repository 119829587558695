import * as THREE from "three";
import {
  IS_DEBUG,
  LIGHT_AMBIENT_COLOR,
  LIGHT_AMBIENT_INTENSITY,
  LIGHT_DIRECT_1_INTRO_ORDERS,
  LIGHT_DIRECT_2_INTRO_ORDERS,
  LIGHT_DIRECT_1_INTRO_SHORT_ORDERS,
  LIGHT_DIRECT_2_INTRO_SHORT_ORDERS,
  LIGHT_DIRECT_1_HOME_POS,
  LIGHT_DIRECT_1_LOADING_POS,
  LIGHT_DIRECT_1_LOADING_TAR_POS,
  LIGHT_DIRECT_1_SIDE_POS,
  LIGHT_DIRECT_1_SINGLE_POS,
  LIGHT_DIRECT_2_HOME_POS,
  LIGHT_DIRECT_2_LOADING_POS,
  LIGHT_DIRECT_2_LOADING_TAR_POS,
  LIGHT_DIRECT_2_SIDE_POS,
  LIGHT_DIRECT_2_SINGLE_POS,
  LIGHT_DIRECT_DEF_TAR_POS,
  TRANSITION_DURATION,
  LIGHT_DIRECT_1_FRONT_POS,
  LIGHT_DIRECT_2_FRONT_POS,
} from "../lib/configs";
import { MODE, ModeTypes } from "../lib/const";
import { store } from "../store/store";
import { TDebug } from "./TDebug";
import { TDirectLight } from "./TDirectLight";

export class TLights {
  private _ambientLight: THREE.AmbientLight;
  private _tDirectLight1: TDirectLight;
  private _tDirectLight2: TDirectLight;

  constructor() {
    this._ambientLight = new THREE.AmbientLight(
      LIGHT_AMBIENT_COLOR,
      LIGHT_AMBIENT_INTENSITY
    );
    this._ambientLight.position.set(0, 50, 0);

    this._tDirectLight1 = new TDirectLight(
      LIGHT_DIRECT_1_LOADING_POS,
      LIGHT_DIRECT_1_LOADING_TAR_POS,
      0xff7777
    );
    this._tDirectLight2 = new TDirectLight(
      LIGHT_DIRECT_2_LOADING_POS,
      LIGHT_DIRECT_2_LOADING_TAR_POS,
      0x77ff77
    );

    if (IS_DEBUG) {
      this.setupDebugGui();
    }
  }

  private setupDebugGui() {
    const gui = TDebug.gui;
    const ambientLightFolder = gui.addFolder("AmbientLight");
    ambientLightFolder.add(this._ambientLight.color, "r", 0, 1);
    ambientLightFolder.add(this._ambientLight.color, "g", 0, 1);
    ambientLightFolder.add(this._ambientLight.color, "b", 0, 1);
    ambientLightFolder.add(this._ambientLight, "intensity", 0, 4);
  }

  private cancelMoveAll() {
    this._tDirectLight1.cancelMoveTo();
    this._tDirectLight2.cancelMoveTo();
  }

  private createAllMoveTimeLine() {
    this._tDirectLight1.createMoveTimelines();
    this._tDirectLight2.createMoveTimelines();
  }

  private changeHomeMode() {
    this._tDirectLight1.addMoveTo(
      TRANSITION_DURATION,
      0,
      LIGHT_DIRECT_1_HOME_POS,
      LIGHT_DIRECT_DEF_TAR_POS
    );
    this._tDirectLight2.addMoveTo(
      TRANSITION_DURATION,
      0,
      LIGHT_DIRECT_2_HOME_POS,
      LIGHT_DIRECT_DEF_TAR_POS
    );
  }

  private changeFrontMode() {
    this._tDirectLight1.addMoveTo(
      TRANSITION_DURATION,
      0,
      LIGHT_DIRECT_1_FRONT_POS,
      LIGHT_DIRECT_DEF_TAR_POS
    );
    this._tDirectLight2.addMoveTo(
      TRANSITION_DURATION,
      0,
      LIGHT_DIRECT_2_FRONT_POS,
      LIGHT_DIRECT_DEF_TAR_POS
    );
  }

  private changeSideMode() {
    this._tDirectLight1.addMoveTo(
      TRANSITION_DURATION,
      0,
      LIGHT_DIRECT_1_SIDE_POS,
      LIGHT_DIRECT_DEF_TAR_POS
    );
    this._tDirectLight2.addMoveTo(
      TRANSITION_DURATION,
      0,
      LIGHT_DIRECT_2_SIDE_POS,
      LIGHT_DIRECT_DEF_TAR_POS
    );
  }

  update(deltaTime: number) {
    if (IS_DEBUG) {
      this._tDirectLight1.update();
      this._tDirectLight2.update();
    }
  }

  changeMode(mode: ModeTypes) {
    this.cancelMoveAll();
    this.createAllMoveTimeLine();

    const { isFinishedIntro } = store.getState().loading;

    if (!isFinishedIntro) {
      if (mode === MODE.HOME) {
        this._tDirectLight1.orderMoves(LIGHT_DIRECT_1_INTRO_ORDERS);
        this._tDirectLight2.orderMoves(LIGHT_DIRECT_2_INTRO_ORDERS);
      } else {
        this._tDirectLight1.orderMoves(LIGHT_DIRECT_1_INTRO_SHORT_ORDERS);
        this._tDirectLight2.orderMoves(LIGHT_DIRECT_2_INTRO_SHORT_ORDERS);
      }
    }

    switch (mode) {
      case MODE.HOME:
      case MODE.SPHERE:
      default:
        this.changeHomeMode();
        break;
      case MODE.ABOUT:
        this._tDirectLight1.addMoveTo(
          TRANSITION_DURATION,
          0,
          LIGHT_DIRECT_1_SINGLE_POS,
          LIGHT_DIRECT_DEF_TAR_POS
        );
        this._tDirectLight2.addMoveTo(
          TRANSITION_DURATION,
          0,
          LIGHT_DIRECT_2_SINGLE_POS,
          LIGHT_DIRECT_DEF_TAR_POS
        );
        break;
      case MODE.FAR:
      case MODE.SERVICE:
        this.changeSideMode();
        break;
      case MODE.FRONT:
        this.changeFrontMode();
        break;
    }
  }

  attachAll(scene: THREE.Scene) {
    scene.add(this._ambientLight);

    this._tDirectLight1.attachAll(scene);
    this._tDirectLight2.attachAll(scene);
  }
}
