import { DotJapanBg } from "../components/atoms/DotJapanBg";
import {
  ContentTitle,
  DList,
  RedTitle,
} from "../components/atoms/SideLayoutObjs";
import { SideLayout } from "../components/layout/SideLayout";

export const CompanyBackLayer = () => {
  return null;
};

export const Company = () => {
  return (
    <div>
      <DotJapanBg />

      <SideLayout>
        <RedTitle>Company</RedTitle>
        <ContentTitle className="mt-6 -ml-0.5 leading-snug">
          株式会社
          <br className="sm:hidden" /> 庄田竜事務所
        </ContentTitle>

        <div className="mt-6">
          <DList title="代表" data="庄田 竜" />
          <DList title="設立" data="2017.02.07" />
          <DList title="資本金" data="3,000,000円" />
          <DList
            title="所在地"
            data={
              <>
                <dl>
                  <dt>本社</dt>
                  <dd>〒160-0022 東京都新宿区新宿7-26-7</dd>
                  <dt className="mt-2">淡路島支店</dt>
                  <dd>〒656-0053 兵庫県洲本市上物部161-9</dd>
                </dl>
              </>
            }
          />
          <DList
            title="取引先銀行"
            data={
              <dl>
                <dd>日本政策金融公庫</dd>
                <dd>東京信用金庫</dd>
              </dl>
            }
          />
          <DList title="代表電話" data="03-6279-4418" />
          <DList title="Email" data="info@ard-online.jp" />
        </div>
      </SideLayout>
    </div>
  );
};
