import { createSlice } from "@reduxjs/toolkit";

interface LoadingState {
  isLoaded: boolean;
  isFinishedIntro: boolean;
  isShortIntro: boolean;
  progress: number;
}

const initialState: LoadingState = {
  isLoaded: false,
  isFinishedIntro: false,
  isShortIntro: false,
  progress: 0,
};

export const loadingSlice = createSlice({
  name: "loading",
  initialState: initialState,
  reducers: {
    setIsLoaded: (state, action) => {
      state.isLoaded = action.payload;
    },
    setIsFinishedIntro: (state, action) => {
      state.isFinishedIntro = action.payload;
    },
    setIsShortIntro: (state, action) => {
      state.isShortIntro = action.payload;
    },
    setLoadingProgress: (state, action) => {
      state.progress = action.payload;
    },
  },
});

export const {
  setIsLoaded,
  setIsFinishedIntro,
  setIsShortIntro,
  setLoadingProgress,
} = loadingSlice.actions;

export default loadingSlice.reducer;
