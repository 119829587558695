import { BlackBackLayer } from "../components/layout/BackgroundLayer";
import { SingleLayout } from "../components/layout/SingleLayout";

export const AboutBackLayer = () => {
  return null;
};

export const About = () => {
  return (
    <div>
      <BlackBackLayer />

      <SingleLayout maxWidth="md">
        <p className="text-center text-xl font-sans italic text-rose-700">
          About
        </p>
        <h1 className="mt-3 text-center text-3xl sm:text-4xl font-medium text-stone-200">
          株式会社庄田竜事務所ってどんな会社？
        </h1>

        <div className="my-10 sm:my-16 max-w-none font-sans prose prose-invert">
          <p>こんにちは、庄田竜です。竜と書いてりょうです。</p>
          <p>
            なんの会社かというと、人々のライフスタイルに衝撃を与えるような仕事を常に考えている会社です。シンプルにいうと「運命を変える」というポジショニングで勝負しようと考えています。
          </p>
          <p>
            売上で勝負するとかそういう土俵ではなく、自分たちはどれだけ多くの方に対し運命を変えるようなことができたのか？という軸で仕事をしています。
          </p>
          <p>
            ですのであれこれできるわけでもありませんので、運命を変える仕事かどうかをまず最優先に意思決定をしています。
          </p>
          <p>
            そのメイン事業として10年以上継続しているオールラウンダー（AR）は、経営、ファイナンス、マーケティング、PRという４つのコアをオールラウンド能力と表現し５０００人を超える方々に育成し、多くの起業家、ベンチャー起業のスタートアップメンバーとして活動したり、オールラウンダーに入会することでステップアップ、転職、その他諸々と貢献してきました。仕事というのは運命を大きく変えるファクターであり、自分の会社の理念と一貫した仕事ができていることに誇りを持っています。
          </p>
          <p>
            しかも一見さんお断りの紹介制、審査性、クローズシステムのみで運営し、今現在もその方針、当初のコミットメントから一切変更はしていません。こちらのプロジェクトに関しましてはご縁のある方により深いプレゼンテーションができる機会があればいいですね。
          </p>
          <p>
            ただ余りにもガチガチだと、息苦しいですし時には遊びも大切だと思っています。仕事というのは不思議であまり生産性ばかり考えると面白くなく、無駄なプロジェクトでもそこでの出会いで後から嘘みたいな展開になることもよくあるケースです。
          </p>
          <p>
            人々のライフスタイルに衝撃を与える、運命を変えるというのがメインコンセプトは変わりませんが、あえて株式会社庄田竜事務所という名前にしたのも、庄田竜が価値を感じている、面白い仕事をしようという自由度を持たせる機能としてあえてこの名前にしました。
          </p>
          <p>
            ポジティブなエネルギーが大切ですので、この会社が多くの人々に影響を与えることができたら最高です。最高な会社を仲間と作っていきたいです。
          </p>
        </div>
      </SingleLayout>
    </div>
  );
};
