import { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { IS_DEBUG } from "../lib/configs";
import { MODE, SITE_PATH } from "../lib/const";
import { setIsShortIntro } from "../store/loadingSlice";
import { useAppDispatch, useAppSelector } from "../store/storeHooks";
import { ThreeApp } from "../three/ThreeApp";

export const ThreeCanvas = () => {
  const { isLoaded } = useAppSelector((state) => state.loading);
  const dispatch = useAppDispatch();
  const location = useLocation();
  const threeApp = useRef<ThreeApp>();
  const canInstance = useRef(true);
  const canvasWrapper = useRef<HTMLDivElement>(undefined!);
  const canLoaderInit = useRef(true);

  useEffect(() => {
    if (canLoaderInit.current) {
      canLoaderInit.current = false;

      if (IS_DEBUG) console.log(`ThreeCanvas: Loaderに初期情報を伝達`);

      if (location.pathname !== SITE_PATH.HOME) {
        dispatch(setIsShortIntro(true));
      }
    }
  }, [dispatch, location]);

  useEffect(() => {
    if (IS_DEBUG) console.log("ThreeCanvas: Try ThreeAppインスタンス化");

    if (canInstance.current) {
      if (IS_DEBUG) console.log(`ThreeCanvas: ThreeAppインスタンス化`);
      canInstance.current = false;

      const threeAppInst = new ThreeApp();

      const canvas = threeAppInst.canvas;
      canvas.style.userSelect = "none";
      canvasWrapper.current.appendChild(canvas);

      threeApp.current = threeAppInst;
    }
  }, [threeApp]);

  useEffect(() => {
    if (isLoaded) {
      if (threeApp.current) {
        if (IS_DEBUG) {
          console.log(
            "ThreeCanvas: location, isLoaded変更検知。ThreeAppに反映"
          );
        }

        switch (location.pathname) {
          case SITE_PATH.HOME:
            threeApp.current.changeMode(MODE.HOME);
            break;
          case SITE_PATH.ABOUT:
            threeApp.current.changeMode(MODE.ABOUT);
            break;
          case SITE_PATH.SERVICE:
            threeApp.current.changeMode(MODE.SERVICE);
            break;
          case SITE_PATH.TEAM:
            threeApp.current.changeMode(MODE.SPHERE);
            break;
          case SITE_PATH.COMPANY:
            threeApp.current.changeMode(MODE.FRONT);
            break;
          case SITE_PATH.CONTACT:
            threeApp.current.changeMode(MODE.FAR);
            break;
        }
      }
    }
  }, [threeApp, isLoaded, location]);

  return (
    <div
      className="fixed inset-0 flex justify-center items-center w-full h-full min-h-full overflow-hidden"
      ref={canvasWrapper}
    ></div>
  );
};
